import { Route, Routes } from 'react-router-dom';
import DesktopComic from './DesktopComic';
import MobileComic from './MobileComic';

import DesktopPreview from './DesktopPreview';
import MobilePreview from './MobilePreview';

// http://localhost:3000/comic-01/101?address=0x7667A06a7b0c7E3185da4FA4e452A9d1cd24D9dB&signature=0x23ed0d7850cc08539245fff6a8c2ca7f081f61d30233acbe86604fc33a644821694a2db4a4538d9fab447c71fb1a2ee2aa39c029a175787657624577382a918d1b

export const App = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/comic-01/preview"
          element={
            <>
              <DesktopPreview />
              <MobilePreview />
            </>
          }
        />
        <Route
          path="/comic-01/:comicId"
          element={
            <>
              <DesktopComic />
              <MobileComic />
            </>
          }
        />
      </Routes>
    </div>
  );
};
