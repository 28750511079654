import { forwardRef } from 'react';

const Page = forwardRef(
  ({ number, img, desc, currentPage, width, isCover }, ref) => {
    // const handleZoom = () => {
    //   document.body.style.zoom = '1.2'; // Adjust the zoom level as needed
    // };

    return (
      <div
        // onClick={handleZoom}
        // className="page"
        ref={ref}
        // data-density={width >= 640 ? 'soft' : 'hard'}
        data-density={width <= 640 || isCover ? 'hard' : 'soft'}
        // data-density={'hard'}
        className="w-full"
      >
        <div className="h-full w-full">
          <div
            className={`
            w-full h-full flex items-center 
            ${
              currentPage > 0 && currentPage % 2 === 0
                ? ' justify-start'
                : currentPage > 0
                ? 'justify-end'
                : ' justify-start'
            }
             `}
          >
            {img}
          </div>
        </div>
      </div>
    );
  }
);

export default Page;
