import React, { useRef, useState, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';
import Page from './Page';

import { COVER_MEDIA_FILES, PREVIEW_COMIC } from 'constants';

const MobilePreview = () => {
  const book = useRef();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={`flex justify-center items-center h-screen relative min-[630px]:hidden overflow-hidden`}
    >
      <div className="absolute w-[85%]  h-screen top-0 left-1/2 -translate-x-1/2 z-10"></div>

      <HTMLFlipBook
        width={550}
        height={windowSize.width >= 640 ? 733 : 850}
        size="stretch"
        minWidth={315}
        maxWidth={875}
        minHeight={400}
        maxHeight={windowSize.height}
        showCover={true}
        mobileScrollSupport={true}
        onChangeOrientation={book.onChangeOrientation}
        onChangeState={book.onChangeState}
        maxShadowOpacity={0}
        className="flex justify-center items-center "
        ref={book}
      >
        {PREVIEW_COMIC.map(({ id, image, desc }, index) => {
          return (
            <Page
              key={id}
              number={id}
              desc={desc}
              currentPage={index}
              width={windowSize.width}
              isCover={
                image === 'null' ||
                COVER_MEDIA_FILES.includes(image.split('/').pop())
              }
              img={
                <>
                  {image.includes('.mp4') ? (
                    <div
                      className={` flex 
                        ${
                          index > 0 && index % 2 === 0
                            ? ' justify-start'
                            : index > 0
                            ? 'justify-end'
                            : ' justify-start'
                        }
                         `}
                    >
                      <video
                        playsInline
                        autoPlay
                        muted
                        loop
                        width="100%"
                        height="70%"
                        className="object-cover sm:hidden"
                      >
                        <source src={image} type="video/mp4" />
                      </video>
                    </div>
                  ) : (
                    <img
                      src={image}
                      alt={desc}
                      className="sm:w-[69.5%] sm:h-[80%]"
                    />
                  )}
                </>
              }
            />
          );
        })}
      </HTMLFlipBook>
    </div>
  );
};

export default MobilePreview;
