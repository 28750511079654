export const COMIC_ADDRESS = '0x21493e9fd8aeec95bf7608a51c75333f40eb192a';

export const COVER_MEDIA_FILES = [
  'eonRift_i1-p01.png',
  'eonRift_i1-p01.mp4',
  'eonRift_i1-p02.png',
  'eonRift_i1-p02.mp4',
  'eonRift_i1-p03.png',
  'eonRift_i1-p03.mp4',
];

export const PREVIEW_COMIC = [
  {
    id: 1,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/new_eonRift_i1-p01.png',
  },
  {
    id: 2,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p04.png',
  },
  {
    id: 3,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p05.png',
  },
  {
    id: 4,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/new_eonRift_i1-p06.png',
  },
  {
    id: 5,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p07.mp4',
  },
  {
    id: 6,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p08.png',
  },
  {
    id: 7,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p09.png',
  },
  {
    id: 8,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p10.png',
  },
  {
    id: 9,
    image: 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p11_public.png',
  },
];

export const COMIC_DISTRIBUTION = [
  {
    'TOKEN ID': 1,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 6,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 7,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 8,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 9,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 10,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 11,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 12,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 13,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 14,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 15,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 16,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 17,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 18,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 19,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 20,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 21,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 22,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 23,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 24,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 25,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 26,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 27,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 28,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 29,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 30,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 31,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 32,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 33,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 34,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 35,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 36,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 37,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 38,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 39,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 40,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 41,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 42,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 43,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 44,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 45,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 46,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 47,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 48,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 49,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 50,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 51,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 52,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 53,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 54,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 55,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 56,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 57,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 58,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 59,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 60,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 61,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 62,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 63,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 64,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 65,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 66,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 67,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 68,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 69,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 70,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 71,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 72,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 73,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 74,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 75,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 76,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 77,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 78,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 79,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 80,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 81,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 82,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 83,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 84,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 85,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 86,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 87,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 88,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 89,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 90,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 91,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 92,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 93,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 94,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 95,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 96,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 97,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 98,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 99,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 100,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 101,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 102,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 103,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 104,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 105,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 106,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 107,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 108,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 109,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 110,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 111,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 112,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 113,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 114,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 115,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 116,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 117,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 118,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 119,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 120,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 121,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 122,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 123,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 124,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 125,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 126,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 127,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 128,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 129,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 130,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 131,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 132,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 133,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 134,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 135,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 136,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 137,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 138,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 139,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 140,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 141,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 142,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 143,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 144,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 145,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 146,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 147,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 148,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 149,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 150,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 151,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 152,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 153,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 154,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 155,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 156,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 157,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 158,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 159,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 160,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 161,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 162,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 163,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 164,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 165,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 166,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 167,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 168,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 169,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 170,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 171,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 172,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 173,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 174,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 175,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 176,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 177,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 178,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 179,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 180,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 181,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 182,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 183,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 184,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 185,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 186,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 187,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 188,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 189,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 190,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 191,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 192,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 193,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 194,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 195,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 196,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 197,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 198,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 199,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 200,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 201,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 202,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 203,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 204,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 205,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 206,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 207,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 208,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 209,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 210,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 211,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 212,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 213,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 214,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 215,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 216,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 217,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 218,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 219,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 220,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 221,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 222,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 223,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 224,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 225,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 226,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 227,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 228,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 229,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 230,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 231,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 232,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 233,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 234,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 235,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 236,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 237,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 238,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 239,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 240,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 241,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 242,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 243,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 244,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 245,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 246,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 247,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 248,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 249,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 250,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 251,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 252,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 253,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 254,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 255,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 256,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 257,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 258,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 259,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 260,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 261,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 262,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 263,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 264,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 265,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 266,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 267,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 268,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 269,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 270,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 271,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 272,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 273,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 274,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 275,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 276,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 277,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 278,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 279,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 280,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 281,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 282,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 283,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 284,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 285,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 286,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 287,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 288,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 289,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 290,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 291,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 292,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 293,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 294,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 295,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 296,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 297,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 298,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 299,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 300,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 301,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 302,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 303,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 304,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 305,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 306,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 307,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 308,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 309,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 310,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 311,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 312,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 313,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 314,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 315,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 316,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 317,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 318,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 319,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 320,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 321,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 322,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 323,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 324,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 325,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 326,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 327,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 328,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 329,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 330,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 331,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 332,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 333,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 334,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 335,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 336,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 337,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 338,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 339,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 340,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 341,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 342,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 343,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 344,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 345,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 346,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 347,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 348,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 349,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 350,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 351,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 352,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 353,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 354,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 355,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 356,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 357,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 358,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 359,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 360,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 361,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 362,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 363,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 364,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 365,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 366,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 367,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 368,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 369,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 370,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 371,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 372,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 373,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 374,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 375,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 376,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 377,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 378,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 379,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 380,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 381,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 382,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 383,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 384,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 385,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 386,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 387,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 388,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 389,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 390,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 391,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 392,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 393,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 394,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 395,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 396,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 397,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 398,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 399,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 400,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 401,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 402,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 403,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 404,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 405,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 406,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 407,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 408,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 409,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 410,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 411,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 412,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 413,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 414,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 415,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 416,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 417,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 418,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 419,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 420,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 421,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 422,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 423,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 424,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 425,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 426,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 427,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 428,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 429,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 430,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 431,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 432,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 433,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 434,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 435,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 436,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 437,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 438,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 439,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 440,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 441,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 442,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 443,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 444,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 445,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 446,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 447,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 448,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 449,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 450,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 451,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 452,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 453,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 454,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 455,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 456,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 457,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 458,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 459,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 460,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 461,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 462,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 463,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 464,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 465,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 466,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 467,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 468,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 469,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 470,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 471,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 472,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 473,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 474,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 475,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 476,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 477,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 478,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 479,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 480,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 481,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 482,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 483,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 484,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 485,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 486,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 487,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 488,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 489,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 490,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 491,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 492,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 493,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 494,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 495,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 496,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 497,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 498,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 499,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 500,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 501,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 502,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 503,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 504,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 505,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 506,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 507,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 508,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 509,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 510,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 511,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 512,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 513,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 514,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 515,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 516,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 517,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 518,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 519,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 520,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 521,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 522,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 523,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 524,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 525,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 526,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 527,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 528,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 529,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 530,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 531,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 532,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 533,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 534,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 535,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 536,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 537,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 538,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 539,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 540,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 541,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 542,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 543,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 544,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 545,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 546,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 547,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 548,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 549,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 550,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 551,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 552,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 553,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 554,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 555,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 556,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 557,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 558,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 559,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 560,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 561,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 562,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 563,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 564,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 565,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 566,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 567,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 568,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 569,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 570,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 571,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 572,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 573,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 574,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 575,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 576,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 577,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 578,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 579,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 580,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 581,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 582,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 583,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 584,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 585,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 586,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 587,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 588,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 589,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 590,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 591,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 592,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 593,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 594,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 595,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 596,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 597,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 598,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 599,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 600,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 601,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 602,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 603,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 604,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 605,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 606,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 607,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 608,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 609,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 610,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 611,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 612,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 613,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 614,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 615,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 616,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 617,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 618,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 619,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 620,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 621,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 622,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 623,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 624,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 625,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 626,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 627,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 628,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 629,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 630,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 631,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 632,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 633,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 634,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 635,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 636,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 637,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 638,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 639,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 640,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 641,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 642,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 643,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 644,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 645,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 646,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 647,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 648,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 649,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 650,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 651,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 652,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 653,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 654,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 655,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 656,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 657,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 658,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 659,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 660,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 661,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 662,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 663,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 664,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 665,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 666,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 667,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 668,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 669,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 670,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 671,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 672,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 673,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 674,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 675,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 676,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 677,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 678,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 679,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 680,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 681,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 682,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 683,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 684,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 685,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 686,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 687,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 688,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 689,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 690,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 691,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 692,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 693,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 694,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 695,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 696,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 697,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 698,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 699,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 700,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 701,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 702,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 703,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 704,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 705,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 706,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 707,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 708,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 709,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 710,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 711,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 712,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 713,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 714,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 715,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 716,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 717,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 718,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 719,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 720,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 721,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 722,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 723,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 724,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 725,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 726,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 727,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 728,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 729,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 730,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 731,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 732,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 733,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 734,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 735,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 736,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 737,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 738,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 739,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 740,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 741,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 742,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 743,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 744,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 745,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 746,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 747,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 748,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 749,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 750,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 751,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 752,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 753,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 754,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 755,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 756,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 757,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 758,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 759,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 760,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 761,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 762,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 763,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 764,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 765,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 766,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 767,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 768,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 769,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 770,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 771,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 772,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 773,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 774,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 775,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 776,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 777,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 778,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 779,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 780,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 781,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 782,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 783,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 784,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 785,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 786,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 787,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 788,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 789,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 790,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 791,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 792,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 793,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 794,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 795,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 796,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 797,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 798,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 799,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 800,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 801,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 802,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 803,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 804,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 805,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 806,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 807,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 808,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 809,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 810,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 811,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 812,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 813,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 814,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 815,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 816,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 817,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 818,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 819,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 820,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 821,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 822,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 823,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 824,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 825,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 826,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 827,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 828,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 829,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 830,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 831,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 832,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 833,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 834,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 835,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 836,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 837,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 838,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 839,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 840,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 841,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 842,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 843,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 844,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 845,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 846,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 847,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 848,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 849,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 850,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 851,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 852,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 853,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 854,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 855,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 856,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 857,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 858,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 859,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 860,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 861,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 862,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 863,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 864,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 865,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 866,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 867,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 868,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 869,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 870,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 871,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 872,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 873,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 874,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 875,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 876,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 877,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 878,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 879,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 880,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 881,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 882,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 883,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 884,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 885,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 886,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 887,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 888,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 889,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 890,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 891,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 892,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 893,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 894,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 895,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 896,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 897,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 898,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 899,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 900,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 901,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 902,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 903,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 904,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 905,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 906,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 907,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 908,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 909,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 910,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 911,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 912,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 913,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 914,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 915,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 916,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 917,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 918,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 919,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 920,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 921,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 922,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 923,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 924,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 925,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 926,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 927,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 928,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 929,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 930,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 931,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 932,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 933,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 934,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 935,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 936,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 937,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 938,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 939,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 940,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 941,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 942,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 943,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 944,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 945,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 946,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 947,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 948,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 949,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 950,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 951,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 952,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 953,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 954,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 955,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 956,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 957,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 958,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 959,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 960,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 961,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 962,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 963,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 964,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 965,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 966,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 967,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 968,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 969,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 970,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 971,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 972,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 973,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 974,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 975,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 976,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 977,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 978,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 979,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 980,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 981,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 982,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 983,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 984,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 985,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 986,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 987,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 988,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 989,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 990,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 991,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 992,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 993,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 994,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 995,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 996,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 997,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 998,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 999,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1000,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1001,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1002,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1003,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1004,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1005,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1006,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1007,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1008,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1009,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1010,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1011,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1012,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1013,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1014,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1015,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1016,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1017,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1018,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1019,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1020,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1021,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1022,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1023,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1024,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1025,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1026,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1027,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1028,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1029,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1030,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1031,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1032,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1033,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1034,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1035,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1036,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1037,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1038,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 1039,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1040,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 1041,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1042,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1043,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1044,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1045,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1046,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1047,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1048,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1049,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1050,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1051,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1052,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1053,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1054,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1055,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1056,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1057,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1058,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1059,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1060,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1061,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1062,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1063,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1064,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 1065,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1066,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1067,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1068,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1069,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1070,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1071,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 1072,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1073,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1074,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1075,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1076,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1077,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1078,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1079,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1080,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1081,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1082,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1083,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1084,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1085,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1086,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1087,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1088,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1089,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1090,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1091,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 1092,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1093,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1094,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1095,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1096,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1097,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1098,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1099,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1100,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1101,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1102,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1103,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1104,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1105,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1106,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1107,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1108,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1109,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1110,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1111,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1112,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1113,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1114,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1115,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1116,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1117,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1118,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1119,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1120,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1121,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1122,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1123,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1124,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1125,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 1126,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1127,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1128,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1129,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1130,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1131,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1132,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1133,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1134,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1135,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1136,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1137,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1138,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1139,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1140,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1141,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1142,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1143,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1144,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1145,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1146,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1147,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1148,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1149,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1150,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1151,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1152,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1153,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1154,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1155,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1156,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1157,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1158,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1159,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1160,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1161,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1162,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1163,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1164,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1165,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1166,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1167,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1168,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1169,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1170,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1171,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1172,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1173,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1174,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1175,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1176,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1177,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1178,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1179,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1180,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1181,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1182,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1183,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1184,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1185,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1186,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1187,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1188,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1189,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1190,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1191,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1192,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1193,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1194,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1195,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1196,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1197,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1198,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1199,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1200,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1201,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1202,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1203,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1204,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1205,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1206,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1207,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1208,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1209,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1210,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1211,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1212,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1213,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1214,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1215,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 1216,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1217,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1218,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1219,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1220,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1221,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1222,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1223,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1224,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1225,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1226,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1227,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1228,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1229,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1230,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1231,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1232,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1233,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1234,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1235,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1236,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1237,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1238,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1239,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1240,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1241,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1242,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1243,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1244,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1245,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1246,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1247,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1248,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1249,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1250,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1251,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1252,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1253,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1254,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1255,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1256,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1257,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1258,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1259,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1260,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 1261,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1262,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1263,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1264,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1265,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1266,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1267,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1268,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1269,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1270,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1271,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1272,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1273,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 1274,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1275,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1276,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1277,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1278,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1279,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1280,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1281,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1282,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1283,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1284,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1285,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1286,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1287,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1288,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1289,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1290,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1291,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1292,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1293,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 1294,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1295,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1296,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1297,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1298,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1299,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1300,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1301,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1302,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1303,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1304,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1305,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1306,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1307,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1308,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1309,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1310,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1311,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1312,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1313,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1314,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1315,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1316,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1317,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1318,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1319,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1320,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1321,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1322,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1323,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1324,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1325,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1326,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1327,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1328,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1329,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1330,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1331,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1332,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1333,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1334,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1335,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1336,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1337,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1338,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1339,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1340,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1341,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1342,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1343,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 1344,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1345,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1346,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1347,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1348,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1349,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1350,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1351,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1352,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 1353,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1354,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1355,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1356,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 1357,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1358,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1359,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1360,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1361,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1362,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1363,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1364,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1365,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 1366,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1367,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1368,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 1369,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1370,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1371,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1372,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1373,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1374,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1375,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1376,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1377,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1378,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1379,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1380,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1381,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1382,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1383,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1384,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1385,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1386,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1387,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1388,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1389,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1390,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1391,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1392,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1393,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1394,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1395,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1396,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1397,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1398,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1399,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1400,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1401,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1402,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 1403,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1404,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1405,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1406,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1407,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1408,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1409,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1410,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1411,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1412,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1413,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1414,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1415,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1416,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1417,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1418,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1419,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1420,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1421,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1422,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1423,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1424,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1425,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1426,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1427,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1428,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1429,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1430,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1431,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1432,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 1433,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1434,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1435,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 1436,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1437,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1438,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1439,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1440,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1441,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1442,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1443,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1444,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1445,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1446,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1447,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1448,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1449,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1450,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1451,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1452,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1453,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1454,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1455,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1456,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1457,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1458,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1459,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1460,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1461,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1462,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1463,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1464,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1465,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1466,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1467,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1468,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1469,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1470,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 1471,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1472,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1473,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1474,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1475,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 1476,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1477,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1478,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1479,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1480,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1481,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1482,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1483,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 1484,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1485,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1486,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1487,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1488,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1489,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1490,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1491,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1492,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1493,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1494,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1495,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1496,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1497,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1498,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 1499,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1500,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1501,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1502,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1503,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1504,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1505,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1506,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1507,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1508,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1509,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1510,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1511,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1512,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1513,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1514,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1515,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1516,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1517,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1518,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1519,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1520,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1521,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1522,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1523,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1524,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1525,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1526,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1527,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1528,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1529,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1530,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1531,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1532,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1533,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1534,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1535,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1536,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1537,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1538,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1539,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1540,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1541,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1542,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1543,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1544,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1545,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1546,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1547,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1548,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1549,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1550,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1551,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1552,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1553,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1554,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1555,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1556,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1557,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1558,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1559,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1560,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1561,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1562,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1563,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1564,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1565,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1566,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1567,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1568,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1569,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1570,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1571,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1572,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1573,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1574,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1575,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1576,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 1577,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1578,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1579,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1580,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1581,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1582,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1583,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1584,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1585,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1586,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1587,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1588,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1589,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1590,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1591,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1592,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1593,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1594,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1595,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1596,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1597,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1598,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1599,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1600,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1601,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1602,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1603,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1604,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1605,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 1606,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1607,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1608,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1609,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1610,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1611,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1612,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1613,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1614,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1615,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1616,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1617,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1618,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1619,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1620,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1621,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1622,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1623,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1624,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1625,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1626,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1627,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1628,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1629,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1630,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 1631,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1632,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1633,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1634,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1635,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1636,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 1637,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1638,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1639,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1640,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1641,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1642,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1643,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1644,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1645,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1646,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 1647,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1648,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1649,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1650,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1651,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1652,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1653,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1654,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1655,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1656,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 1657,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1658,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1659,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1660,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1661,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1662,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 1663,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1664,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1665,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1666,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1667,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1668,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1669,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1670,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1671,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1672,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1673,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1674,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1675,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1676,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1677,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1678,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1679,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1680,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1681,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1682,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1683,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1684,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1685,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1686,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1687,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1688,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 1689,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1690,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1691,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1692,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1693,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1694,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1695,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1696,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1697,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1698,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 1699,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1700,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1701,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1702,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1703,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1704,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1705,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 1706,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 1707,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1708,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1709,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1710,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1711,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1712,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1713,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1714,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1715,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1716,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 1717,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1718,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1719,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1720,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1721,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1722,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1723,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1724,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1725,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1726,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1727,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 1728,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1729,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1730,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1731,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1732,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1733,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1734,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1735,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1736,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1737,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1738,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1739,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1740,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1741,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1742,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1743,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1744,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1745,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1746,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1747,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1748,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1749,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1750,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1751,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1752,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1753,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1754,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1755,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1756,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1757,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1758,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1759,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1760,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1761,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1762,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1763,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1764,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1765,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1766,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1767,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1768,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1769,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1770,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1771,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1772,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1773,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1774,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 1775,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1776,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1777,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1778,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1779,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1780,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1781,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1782,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1783,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1784,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1785,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1786,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1787,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1788,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1789,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1790,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1791,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1792,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 1793,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1794,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1795,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1796,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1797,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1798,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1799,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1800,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1801,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1802,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1803,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1804,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1805,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1806,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1807,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1808,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1809,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1810,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1811,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1812,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1813,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1814,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1815,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1816,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1817,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 1818,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1819,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1820,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1821,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1822,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1823,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1824,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1825,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1826,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1827,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1828,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1829,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1830,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1831,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1832,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1833,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1834,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1835,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1836,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1837,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1838,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1839,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1840,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1841,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1842,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1843,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1844,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1845,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1846,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1847,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1848,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1849,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1850,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1851,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1852,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1853,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1854,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1855,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1856,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1857,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1858,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1859,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1860,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1861,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1862,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1863,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1864,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1865,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1866,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1867,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1868,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1869,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1870,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1871,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1872,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1873,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1874,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1875,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1876,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1877,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1878,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1879,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1880,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1881,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1882,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1883,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1884,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1885,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1886,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1887,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1888,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 1889,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1890,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1891,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1892,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1893,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1894,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1895,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1896,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1897,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1898,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1899,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1900,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1901,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1902,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1903,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1904,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1905,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1906,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1907,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1908,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1909,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1910,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1911,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1912,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1913,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1914,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1915,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1916,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1917,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1918,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1919,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1920,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 1921,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1922,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1923,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1924,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1925,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1926,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1927,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1928,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1929,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1930,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1931,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1932,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1933,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1934,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1935,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1936,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1937,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1938,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1939,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1940,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 1941,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1942,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1943,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1944,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 1945,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1946,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1947,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1948,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1949,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1950,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1951,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1952,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1953,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1954,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1955,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1956,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1957,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1958,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1959,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 1960,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1961,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1962,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1963,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 1964,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1965,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1966,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1967,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1968,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1969,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1970,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 1971,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1972,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1973,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1974,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1975,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1976,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 1977,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1978,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1979,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1980,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1981,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1982,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1983,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1984,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1985,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1986,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 1987,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1988,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1989,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 1990,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 1991,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 1992,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1993,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 1994,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 1995,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1996,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 1997,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 1998,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 1999,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2000,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2001,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2002,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2003,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 2004,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2005,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 2006,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2007,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2008,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2009,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 2010,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2011,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2012,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2013,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2014,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2015,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2016,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 2017,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2018,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2019,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2020,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2021,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2022,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2023,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2024,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2025,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2026,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2027,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2028,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2029,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2030,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2031,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2032,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2033,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2034,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2035,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2036,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2037,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2038,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2039,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2040,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2041,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 2042,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2043,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2044,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2045,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2046,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2047,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2048,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2049,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2050,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2051,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2052,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2053,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2054,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2055,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2056,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2057,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2058,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2059,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 2060,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2061,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2062,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2063,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2064,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 2065,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2066,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2067,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2068,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2069,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 2070,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 2071,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2072,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2073,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2074,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2075,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2076,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2077,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2078,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2079,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2080,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2081,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2082,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2083,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2084,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2085,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2086,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2087,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2088,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2089,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 2090,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2091,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2092,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2093,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2094,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2095,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2096,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2097,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2098,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2099,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2100,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2101,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2102,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2103,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2104,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2105,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2106,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 2107,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2108,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 2109,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2110,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2111,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2112,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2113,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2114,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2115,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2116,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2117,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2118,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2119,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2120,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2121,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2122,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2123,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2124,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2125,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2126,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2127,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2128,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2129,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2130,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2131,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2132,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2133,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2134,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2135,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2136,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2137,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2138,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2139,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2140,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2141,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2142,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2143,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2144,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2145,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2146,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2147,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2148,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 2149,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2150,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2151,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2152,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2153,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2154,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 2155,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2156,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2157,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2158,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2159,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2160,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2161,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2162,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2163,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2164,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2165,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2166,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2167,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2168,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2169,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2170,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2171,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2172,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2173,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2174,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2175,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2176,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2177,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2178,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2179,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2180,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2181,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2182,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2183,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2184,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2185,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2186,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2187,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 2188,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2189,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2190,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 2191,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2192,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2193,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2194,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2195,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2196,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2197,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2198,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2199,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2200,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2201,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2202,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2203,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2204,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2205,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2206,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2207,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 2208,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2209,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2210,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2211,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2212,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2213,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2214,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2215,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2216,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2217,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2218,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2219,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2220,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 2221,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2222,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2223,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2224,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2225,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2226,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2227,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2228,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2229,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2230,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2231,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2232,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2233,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2234,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2235,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2236,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2237,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2238,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2239,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2240,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2241,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2242,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2243,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2244,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2245,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2246,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2247,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2248,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2249,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2250,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2251,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2252,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2253,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2254,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2255,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2256,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2257,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2258,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2259,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2260,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2261,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2262,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2263,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2264,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2265,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2266,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2267,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2268,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2269,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2270,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2271,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2272,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2273,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 2274,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2275,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2276,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2277,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2278,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2279,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2280,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2281,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2282,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2283,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2284,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2285,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2286,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2287,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2288,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2289,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2290,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2291,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2292,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2293,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2294,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2295,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2296,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2297,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2298,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2299,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2300,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2301,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2302,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2303,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2304,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2305,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2306,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2307,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2308,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2309,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2310,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2311,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2312,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2313,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2314,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2315,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2316,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2317,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2318,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 2319,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2320,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2321,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2322,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2323,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2324,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2325,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2326,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2327,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2328,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2329,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2330,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2331,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2332,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2333,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2334,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2335,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2336,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2337,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2338,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2339,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2340,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2341,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2342,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2343,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2344,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2345,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2346,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2347,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2348,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2349,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2350,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2351,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 2352,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2353,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2354,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2355,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2356,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2357,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2358,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2359,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2360,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2361,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2362,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2363,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2364,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2365,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2366,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2367,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2368,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2369,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2370,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2371,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2372,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2373,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2374,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2375,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2376,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2377,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2378,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2379,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2380,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2381,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2382,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2383,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2384,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2385,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2386,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2387,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2388,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2389,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2390,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2391,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2392,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2393,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2394,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2395,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2396,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2397,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2398,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2399,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2400,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 2401,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2402,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2403,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2404,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2405,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2406,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2407,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2408,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2409,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2410,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2411,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2412,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 2413,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2414,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2415,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2416,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2417,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 2418,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2419,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2420,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2421,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2422,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2423,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2424,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2425,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2426,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2427,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 2428,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2429,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2430,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2431,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2432,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2433,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2434,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2435,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2436,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2437,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2438,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2439,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2440,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2441,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2442,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2443,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2444,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2445,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2446,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2447,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2448,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2449,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2450,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2451,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2452,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2453,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2454,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2455,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 2456,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2457,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2458,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 2459,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 2460,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2461,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2462,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2463,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2464,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2465,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2466,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2467,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2468,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2469,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2470,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2471,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 2472,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2473,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2474,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2475,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2476,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2477,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2478,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2479,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2480,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2481,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2482,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2483,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 2484,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2485,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2486,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2487,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2488,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2489,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2490,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2491,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2492,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2493,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2494,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2495,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2496,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2497,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2498,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2499,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2500,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2501,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2502,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2503,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2504,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2505,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2506,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2507,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2508,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 2509,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2510,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2511,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2512,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2513,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2514,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2515,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2516,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2517,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2518,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2519,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2520,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2521,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2522,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2523,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2524,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2525,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2526,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2527,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2528,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2529,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2530,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2531,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2532,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2533,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2534,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2535,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2536,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2537,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2538,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2539,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2540,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2541,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2542,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2543,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2544,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2545,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2546,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2547,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2548,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2549,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2550,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2551,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2552,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2553,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2554,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2555,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2556,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2557,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2558,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2559,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2560,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2561,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2562,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2563,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2564,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2565,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2566,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2567,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2568,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 2569,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2570,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2571,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2572,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2573,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2574,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2575,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2576,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2577,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2578,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 2579,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2580,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2581,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2582,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2583,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2584,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2585,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2586,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2587,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2588,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2589,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2590,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2591,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2592,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2593,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2594,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2595,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2596,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2597,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2598,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2599,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2600,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2601,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2602,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2603,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2604,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2605,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2606,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2607,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2608,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2609,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2610,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2611,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2612,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2613,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2614,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2615,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2616,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2617,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2618,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2619,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2620,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2621,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2622,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2623,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2624,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2625,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2626,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2627,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2628,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2629,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2630,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2631,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2632,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2633,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2634,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2635,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2636,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2637,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2638,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2639,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2640,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2641,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2642,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2643,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2644,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2645,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2646,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2647,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2648,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2649,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2650,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2651,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2652,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2653,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2654,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2655,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2656,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2657,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2658,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2659,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2660,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2661,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2662,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2663,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2664,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2665,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2666,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2667,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2668,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2669,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2670,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2671,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2672,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2673,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2674,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2675,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2676,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2677,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2678,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2679,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 2680,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2681,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2682,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2683,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2684,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2685,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2686,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2687,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2688,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2689,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2690,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2691,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2692,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2693,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2694,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 2695,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2696,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2697,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2698,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2699,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2700,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2701,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2702,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2703,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2704,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2705,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2706,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2707,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2708,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2709,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2710,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2711,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2712,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2713,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2714,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2715,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2716,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2717,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2718,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2719,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2720,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2721,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2722,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2723,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2724,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2725,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2726,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2727,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2728,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2729,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2730,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2731,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 2732,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2733,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2734,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2735,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 2736,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2737,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2738,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2739,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2740,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2741,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2742,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2743,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2744,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2745,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2746,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2747,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2748,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2749,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2750,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2751,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2752,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2753,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2754,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2755,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2756,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2757,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2758,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2759,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2760,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2761,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2762,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2763,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2764,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2765,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2766,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2767,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2768,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2769,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2770,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2771,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2772,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2773,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2774,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2775,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2776,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2777,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2778,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2779,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2780,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2781,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 2782,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2783,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2784,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2785,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2786,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2787,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2788,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2789,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 2790,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2791,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2792,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2793,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2794,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2795,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2796,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2797,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2798,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2799,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2800,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2801,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2802,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2803,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2804,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2805,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2806,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2807,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2808,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2809,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 2810,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2811,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2812,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2813,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2814,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2815,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2816,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2817,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2818,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2819,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2820,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2821,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2822,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2823,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2824,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2825,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2826,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2827,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2828,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2829,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2830,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2831,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2832,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2833,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2834,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2835,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2836,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2837,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2838,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2839,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2840,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2841,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2842,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2843,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2844,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2845,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2846,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2847,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2848,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2849,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2850,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2851,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2852,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2853,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2854,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 2855,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2856,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2857,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2858,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2859,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2860,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2861,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2862,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2863,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2864,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2865,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 2866,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2867,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 2868,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2869,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2870,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 2871,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2872,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2873,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2874,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2875,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2876,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2877,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2878,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2879,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2880,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2881,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2882,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2883,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2884,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2885,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2886,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2887,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2888,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2889,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2890,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2891,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2892,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2893,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2894,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2895,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2896,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2897,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2898,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2899,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2900,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2901,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2902,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2903,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2904,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2905,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2906,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2907,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2908,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 2909,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2910,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2911,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2912,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2913,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2914,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2915,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2916,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2917,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2918,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2919,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2920,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2921,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2922,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2923,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2924,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2925,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2926,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2927,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2928,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 2929,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2930,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2931,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2932,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2933,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2934,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2935,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2936,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2937,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2938,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2939,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2940,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2941,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2942,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2943,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2944,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2945,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2946,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2947,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2948,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2949,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2950,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2951,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2952,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2953,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2954,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2955,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2956,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2957,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2958,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2959,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2960,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2961,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2962,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2963,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2964,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2965,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2966,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2967,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 2968,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2969,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2970,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2971,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2972,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2973,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 2974,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2975,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2976,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2977,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2978,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 2979,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 2980,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 2981,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2982,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2983,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2984,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2985,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 2986,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2987,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2988,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2989,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2990,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2991,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2992,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 2993,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2994,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2995,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 2996,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 2997,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 2998,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 2999,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3000,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3001,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3002,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3003,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3004,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3005,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3006,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3007,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3008,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3009,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3010,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3011,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3012,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3013,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3014,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3015,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3016,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3017,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3018,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3019,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3020,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3021,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 3022,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3023,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3024,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3025,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3026,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3027,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3028,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3029,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3030,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3031,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3032,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3033,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3034,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3035,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3036,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3037,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3038,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3039,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3040,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3041,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3042,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3043,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3044,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3045,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3046,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3047,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3048,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3049,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3050,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3051,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3052,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3053,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3054,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3055,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3056,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3057,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3058,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3059,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3060,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 3061,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3062,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3063,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3064,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 3065,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3066,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3067,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3068,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 3069,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3070,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3071,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3072,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3073,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3074,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3075,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3076,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3077,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3078,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3079,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3080,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3081,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3082,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3083,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3084,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3085,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3086,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3087,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3088,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3089,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3090,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3091,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3092,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3093,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3094,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3095,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3096,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3097,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3098,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3099,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3100,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3101,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3102,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3103,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 3104,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3105,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3106,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3107,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3108,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3109,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3110,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3111,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3112,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3113,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3114,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3115,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3116,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3117,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3118,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3119,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3120,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3121,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 3122,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3123,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3124,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3125,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3126,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3127,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3128,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3129,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3130,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3131,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3132,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3133,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3134,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3135,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3136,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3137,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3138,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3139,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3140,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3141,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3142,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3143,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3144,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 3145,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3146,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3147,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3148,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3149,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3150,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3151,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3152,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 3153,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3154,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3155,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3156,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3157,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3158,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3159,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3160,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3161,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3162,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3163,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3164,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3165,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3166,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3167,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3168,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3169,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3170,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3171,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3172,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3173,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3174,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3175,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3176,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3177,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3178,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3179,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3180,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3181,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3182,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3183,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3184,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3185,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3186,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3187,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3188,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3189,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3190,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3191,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 3192,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3193,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3194,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3195,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3196,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3197,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3198,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3199,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3200,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3201,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3202,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3203,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3204,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3205,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3206,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3207,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3208,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3209,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3210,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3211,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3212,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3213,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3214,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3215,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3216,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3217,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3218,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3219,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3220,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3221,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3222,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3223,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3224,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3225,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3226,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3227,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3228,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3229,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 3230,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3231,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3232,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3233,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3234,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3235,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3236,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3237,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3238,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3239,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3240,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3241,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3242,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3243,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3244,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3245,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3246,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3247,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3248,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3249,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3250,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3251,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3252,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3253,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 3254,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3255,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 3256,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3257,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3258,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3259,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3260,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3261,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3262,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3263,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3264,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3265,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3266,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3267,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 3268,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3269,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3270,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3271,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3272,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3273,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3274,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3275,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3276,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3277,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3278,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3279,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3280,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3281,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3282,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3283,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3284,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3285,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3286,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3287,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3288,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3289,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3290,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3291,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3292,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3293,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3294,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3295,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3296,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3297,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3298,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3299,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3300,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3301,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3302,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3303,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3304,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3305,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3306,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3307,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3308,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3309,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 3310,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3311,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3312,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3313,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3314,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3315,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3316,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3317,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3318,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3319,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3320,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3321,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3322,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3323,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3324,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3325,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3326,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3327,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 3328,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3329,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3330,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3331,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3332,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3333,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3334,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3335,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3336,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3337,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3338,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3339,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3340,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3341,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3342,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3343,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3344,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3345,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3346,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3347,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3348,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3349,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3350,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3351,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3352,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3353,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3354,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3355,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3356,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3357,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3358,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3359,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3360,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3361,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3362,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3363,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3364,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3365,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3366,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3367,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3368,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3369,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3370,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3371,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3372,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3373,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3374,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3375,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3376,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3377,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 3378,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3379,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3380,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3381,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3382,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3383,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3384,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3385,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3386,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3387,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3388,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 3389,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3390,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3391,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3392,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3393,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3394,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3395,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3396,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3397,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3398,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3399,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3400,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3401,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3402,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3403,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3404,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3405,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3406,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3407,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3408,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 3409,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3410,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3411,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3412,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 3413,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3414,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3415,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3416,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3417,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3418,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 3419,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3420,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3421,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3422,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3423,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3424,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3425,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 3426,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3427,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3428,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3429,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3430,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3431,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3432,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3433,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3434,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3435,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3436,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3437,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3438,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3439,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3440,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3441,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3442,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3443,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3444,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3445,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3446,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3447,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3448,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3449,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3450,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3451,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3452,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3453,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3454,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3455,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3456,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3457,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3458,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3459,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3460,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3461,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3462,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 3463,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3464,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3465,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3466,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3467,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3468,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3469,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3470,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3471,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3472,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 3473,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3474,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3475,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3476,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3477,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 3478,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3479,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3480,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3481,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3482,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3483,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3484,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3485,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3486,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3487,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3488,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3489,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3490,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3491,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3492,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3493,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3494,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3495,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3496,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3497,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3498,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3499,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3500,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3501,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3502,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3503,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3504,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3505,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3506,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3507,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3508,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3509,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3510,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3511,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3512,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 3513,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3514,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3515,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3516,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3517,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3518,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3519,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3520,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3521,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3522,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3523,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3524,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3525,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3526,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3527,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3528,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3529,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3530,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3531,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3532,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3533,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3534,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3535,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3536,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3537,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3538,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3539,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3540,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3541,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3542,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3543,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3544,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3545,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3546,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3547,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3548,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3549,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3550,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3551,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3552,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3553,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3554,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3555,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3556,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3557,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3558,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3559,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3560,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3561,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3562,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3563,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3564,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3565,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3566,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3567,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3568,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3569,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3570,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3571,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3572,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3573,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3574,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3575,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3576,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3577,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3578,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3579,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 3580,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3581,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3582,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3583,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3584,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3585,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3586,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3587,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3588,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3589,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3590,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3591,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3592,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3593,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3594,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3595,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3596,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3597,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3598,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3599,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3600,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3601,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3602,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3603,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3604,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3605,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3606,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3607,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3608,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 3609,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3610,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3611,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3612,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3613,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3614,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3615,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3616,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3617,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3618,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3619,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3620,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3621,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3622,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3623,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3624,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3625,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3626,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3627,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3628,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3629,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3630,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3631,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3632,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3633,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3634,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3635,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3636,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 3637,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3638,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3639,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 3640,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 3641,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3642,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3643,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3644,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3645,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3646,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3647,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3648,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3649,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3650,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3651,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3652,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3653,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3654,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3655,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3656,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3657,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3658,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3659,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3660,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3661,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3662,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3663,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3664,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3665,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3666,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3667,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3668,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3669,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3670,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3671,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3672,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3673,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3674,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3675,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3676,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 3677,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3678,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3679,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3680,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3681,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3682,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 3683,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3684,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3685,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3686,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3687,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3688,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3689,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3690,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3691,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3692,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3693,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3694,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3695,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3696,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3697,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3698,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3699,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3700,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3701,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3702,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3703,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3704,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3705,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3706,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3707,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3708,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3709,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 3710,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3711,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3712,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3713,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3714,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3715,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3716,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3717,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3718,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3719,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3720,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3721,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3722,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3723,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3724,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3725,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3726,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3727,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3728,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3729,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3730,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3731,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3732,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3733,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3734,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3735,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3736,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3737,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3738,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3739,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3740,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3741,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3742,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3743,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3744,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3745,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3746,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3747,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3748,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3749,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3750,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3751,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3752,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3753,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3754,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3755,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3756,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3757,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3758,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3759,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3760,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 3761,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3762,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3763,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3764,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 3765,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3766,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3767,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3768,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 3769,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3770,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3771,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3772,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3773,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3774,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3775,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3776,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3777,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3778,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3779,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3780,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3781,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 3782,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 3783,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3784,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3785,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3786,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3787,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3788,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3789,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3790,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3791,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3792,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3793,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3794,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 3795,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3796,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3797,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3798,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3799,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3800,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3801,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3802,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 3803,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3804,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 3805,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3806,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3807,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3808,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 3809,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3810,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3811,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3812,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3813,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3814,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3815,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3816,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3817,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3818,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3819,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3820,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3821,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3822,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3823,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3824,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3825,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3826,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3827,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3828,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3829,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3830,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3831,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3832,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3833,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3834,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3835,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3836,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3837,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3838,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3839,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3840,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3841,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3842,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3843,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3844,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3845,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 3846,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3847,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3848,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3849,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3850,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3851,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3852,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3853,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3854,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3855,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3856,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3857,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3858,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3859,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3860,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3861,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3862,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3863,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3864,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3865,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3866,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3867,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3868,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3869,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3870,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3871,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3872,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3873,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3874,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3875,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3876,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3877,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3878,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3879,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3880,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3881,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3882,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3883,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 3884,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3885,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3886,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3887,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3888,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3889,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3890,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3891,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3892,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3893,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3894,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3895,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3896,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3897,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3898,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3899,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3900,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3901,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3902,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3903,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3904,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3905,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3906,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 3907,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3908,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3909,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3910,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3911,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3912,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3913,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 3914,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3915,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3916,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3917,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3918,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3919,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3920,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3921,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3922,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3923,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3924,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3925,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3926,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3927,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3928,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3929,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3930,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3931,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3932,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3933,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3934,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3935,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3936,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 3937,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3938,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3939,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3940,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3941,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3942,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3943,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3944,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3945,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3946,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3947,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3948,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3949,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3950,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3951,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3952,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3953,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3954,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3955,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3956,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 3957,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 3958,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3959,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3960,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 3961,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3962,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3963,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3964,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3965,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3966,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3967,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3968,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3969,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3970,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3971,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3972,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3973,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3974,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 3975,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3976,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3977,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3978,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3979,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3980,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3981,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3982,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 3983,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3984,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3985,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3986,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3987,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 3988,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3989,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 3990,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3991,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3992,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 3993,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 3994,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 3995,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3996,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 3997,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 3998,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 3999,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4000,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4001,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4002,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4003,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4004,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4005,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4006,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4007,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4008,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4009,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4010,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4011,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4012,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4013,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4014,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4015,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4016,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4017,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4018,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4019,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4020,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4021,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4022,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4023,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4024,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4025,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4026,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4027,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4028,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4029,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4030,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4031,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4032,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4033,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4034,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4035,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4036,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4037,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4038,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4039,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4040,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4041,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 4042,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4043,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4044,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4045,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4046,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4047,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4048,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4049,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4050,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4051,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4052,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4053,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4054,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4055,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4056,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4057,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4058,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4059,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4060,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 4061,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4062,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4063,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4064,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4065,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4066,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 4067,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4068,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4069,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4070,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4071,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4072,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4073,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4074,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4075,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4076,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4077,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4078,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4079,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4080,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4081,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4082,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4083,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4084,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4085,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4086,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4087,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4088,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4089,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4090,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4091,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4092,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4093,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4094,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4095,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 4096,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4097,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4098,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4099,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4100,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4101,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4102,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4103,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4104,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4105,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4106,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4107,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4108,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4109,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4110,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4111,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4112,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4113,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4114,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4115,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4116,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 4117,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4118,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4119,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4120,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4121,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4122,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4123,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4124,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4125,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4126,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4127,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4128,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4129,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4130,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4131,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4132,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4133,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4134,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4135,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4136,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4137,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4138,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4139,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4140,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4141,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4142,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4143,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4144,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4145,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4146,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4147,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4148,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4149,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 4150,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 4151,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4152,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4153,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4154,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4155,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4156,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 4157,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4158,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4159,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4160,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4161,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4162,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4163,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4164,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 4165,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4166,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4167,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4168,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4169,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4170,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4171,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4172,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 4173,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 4174,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4175,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4176,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4177,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4178,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4179,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4180,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4181,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4182,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4183,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4184,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4185,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4186,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4187,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4188,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4189,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4190,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 4191,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4192,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4193,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4194,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4195,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4196,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4197,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4198,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 4199,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4200,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4201,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4202,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4203,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4204,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4205,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4206,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4207,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4208,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4209,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4210,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4211,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4212,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 4213,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4214,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4215,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4216,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4217,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4218,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4219,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4220,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 4221,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4222,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4223,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4224,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4225,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4226,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4227,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4228,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4229,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4230,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4231,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4232,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4233,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4234,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4235,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4236,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4237,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4238,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4239,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4240,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4241,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4242,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4243,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4244,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4245,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4246,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4247,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4248,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4249,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4250,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4251,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4252,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4253,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4254,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4255,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4256,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4257,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4258,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4259,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4260,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4261,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4262,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4263,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4264,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 4265,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4266,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4267,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4268,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4269,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4270,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4271,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4272,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4273,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4274,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4275,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4276,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 4277,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 4278,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4279,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4280,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4281,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4282,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4283,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4284,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4285,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4286,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4287,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4288,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4289,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4290,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4291,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4292,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4293,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4294,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4295,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4296,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4297,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4298,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4299,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4300,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4301,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4302,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4303,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4304,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4305,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4306,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4307,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4308,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4309,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4310,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4311,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4312,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4313,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4314,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4315,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4316,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4317,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4318,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4319,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4320,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4321,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4322,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4323,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4324,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4325,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4326,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4327,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4328,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4329,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4330,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4331,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4332,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4333,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4334,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4335,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4336,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4337,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4338,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4339,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4340,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4341,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4342,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4343,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4344,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4345,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4346,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4347,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4348,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4349,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4350,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4351,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4352,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4353,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4354,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4355,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4356,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4357,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4358,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4359,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4360,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4361,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4362,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4363,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4364,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4365,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4366,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4367,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4368,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4369,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4370,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4371,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4372,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4373,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4374,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4375,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4376,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4377,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4378,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4379,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4380,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4381,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4382,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 4383,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4384,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4385,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4386,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4387,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4388,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4389,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4390,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4391,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4392,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4393,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 4394,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4395,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4396,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4397,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4398,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4399,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4400,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4401,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4402,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4403,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4404,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4405,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4406,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4407,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4408,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4409,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4410,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4411,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4412,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4413,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4414,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4415,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4416,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4417,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4418,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4419,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4420,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4421,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4422,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4423,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4424,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4425,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4426,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4427,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4428,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4429,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4430,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4431,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4432,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4433,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4434,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4435,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4436,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4437,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 4438,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4439,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4440,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4441,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 4442,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4443,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4444,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4445,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4446,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4447,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4448,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4449,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4450,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4451,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4452,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4453,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4454,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4455,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4456,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4457,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4458,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4459,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4460,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4461,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 4462,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4463,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4464,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4465,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4466,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4467,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4468,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4469,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4470,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4471,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 4472,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4473,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4474,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4475,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4476,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4477,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4478,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4479,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4480,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4481,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4482,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4483,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4484,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4485,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4486,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4487,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4488,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4489,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4490,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4491,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4492,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4493,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4494,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4495,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4496,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4497,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4498,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4499,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4500,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4501,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4502,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4503,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4504,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4505,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4506,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4507,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4508,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4509,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4510,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4511,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4512,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4513,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4514,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4515,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4516,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4517,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4518,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4519,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4520,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4521,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4522,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4523,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4524,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4525,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4526,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4527,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4528,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 4529,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4530,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4531,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4532,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4533,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4534,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4535,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4536,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4537,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4538,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4539,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4540,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4541,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4542,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4543,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4544,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4545,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4546,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4547,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 4548,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4549,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4550,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4551,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4552,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4553,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4554,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4555,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4556,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4557,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4558,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4559,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4560,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4561,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4562,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4563,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4564,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4565,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4566,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4567,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 4568,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4569,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 4570,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4571,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4572,
    'READER VARIANT': "Artist's Proof Edition V14",
  },
  {
    'TOKEN ID': 4573,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 4574,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4575,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4576,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4577,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4578,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4579,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 4580,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4581,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 4582,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4583,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4584,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4585,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 4586,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4587,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4588,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4589,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4590,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4591,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 4592,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 4593,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4594,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4595,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4596,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4597,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4598,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4599,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4600,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4601,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 4602,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 4603,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 4604,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 4605,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 4606,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 4607,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 4608,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 4609,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 4610,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 4611,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 4612,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 4613,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 4614,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 4615,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4616,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4617,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4618,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4619,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4620,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4621,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4622,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4623,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4624,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4625,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4626,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4627,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4628,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4629,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4630,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4631,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4632,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4633,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4634,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4635,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4636,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4637,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4638,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4639,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4640,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4641,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4642,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4643,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4644,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4645,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4646,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4647,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4648,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4649,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4650,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4651,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4652,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4653,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4654,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4655,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4656,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4657,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4658,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4659,
    'READER VARIANT': 'First Edition V14',
  },
  {
    'TOKEN ID': 4660,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4661,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4662,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4663,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4664,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4665,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4666,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4667,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4668,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4669,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4670,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4671,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4672,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4673,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4674,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4675,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4676,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4677,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4678,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4679,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4680,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4681,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4682,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4683,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4684,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4685,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4686,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4687,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4688,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4689,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4690,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4691,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4692,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4693,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4694,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4695,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4696,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4697,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4698,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4699,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4700,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4701,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4702,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4703,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4704,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4705,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4706,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4707,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4708,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4709,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4710,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4711,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4712,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4713,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4714,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4715,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4716,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4717,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4718,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4719,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4720,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4721,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4722,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4723,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4724,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 4725,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4726,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4727,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4728,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4729,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 4730,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4731,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4732,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4733,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4734,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4735,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4736,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4737,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4738,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4739,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4740,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4741,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4742,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4743,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4744,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4745,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4746,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4747,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4748,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4749,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4750,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4751,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4752,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4753,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4754,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4755,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4756,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4757,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4758,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4759,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4760,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4761,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4762,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4763,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4764,
    'READER VARIANT': 'First Edition V6',
  },
  {
    'TOKEN ID': 4765,
    'READER VARIANT': 'First Edition V12',
  },
  {
    'TOKEN ID': 4766,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4767,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4768,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4769,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4770,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4771,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4772,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4773,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4774,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4775,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4776,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4777,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4778,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4779,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4780,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4781,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4782,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4783,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4784,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4785,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4786,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 4787,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4788,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4789,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4790,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4791,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4792,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4793,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4794,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4795,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4796,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4797,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4798,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4799,
    'READER VARIANT': 'First Edition V8',
  },
  {
    'TOKEN ID': 4800,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4801,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4802,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4803,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4804,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4805,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4806,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4807,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4808,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4809,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4810,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4811,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4812,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4813,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4814,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4815,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4816,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4817,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4818,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4819,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4820,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 4821,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 4822,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4823,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4824,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4825,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4826,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4827,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4828,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4829,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4830,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4831,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4832,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4833,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4834,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4835,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4836,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4837,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4838,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4839,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4840,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4841,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4842,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4843,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4844,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4845,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4846,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4847,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4848,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4849,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4850,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4851,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4852,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4853,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4854,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4855,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4856,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4857,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4858,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4859,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4860,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4861,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4862,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4863,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4864,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4865,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4866,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4867,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4868,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4869,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4870,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4871,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4872,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4873,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4874,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4875,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4876,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4877,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4878,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4879,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4880,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4881,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4882,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4883,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4884,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4885,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4886,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4887,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4888,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 4889,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4890,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4891,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4892,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4893,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4894,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4895,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4896,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4897,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4898,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4899,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4900,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4901,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4902,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4903,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4904,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4905,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4906,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4907,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4908,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4909,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4910,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4911,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4912,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4913,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4914,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4915,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4916,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4917,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4918,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4919,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4920,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4921,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4922,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4923,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4924,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4925,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4926,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4927,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4928,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4929,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4930,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4931,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4932,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4933,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 4934,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4935,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4936,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4937,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4938,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4939,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4940,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4941,
    'READER VARIANT': 'First Edition V20',
  },
  {
    'TOKEN ID': 4942,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4943,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4944,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4945,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4946,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4947,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4948,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4949,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4950,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4951,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4952,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4953,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4954,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4955,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4956,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 4957,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4958,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4959,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4960,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4961,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4962,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 4963,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4964,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4965,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4966,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4967,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4968,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4969,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4970,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4971,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4972,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4973,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4974,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4975,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 4976,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4977,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4978,
    'READER VARIANT': 'First Edition V8',
  },
  {
    'TOKEN ID': 4979,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4980,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 4981,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4982,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 4983,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 4984,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4985,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4986,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4987,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4988,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4989,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 4990,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 4991,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 4992,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4993,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 4994,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4995,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 4996,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 4997,
    'READER VARIANT': 'First Edition V6',
  },
  {
    'TOKEN ID': 4998,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 4999,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5000,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5001,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5002,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5003,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5004,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5005,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 5006,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5007,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5008,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5009,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5010,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5011,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5012,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5013,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5014,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5015,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5016,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5017,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5018,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5019,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5020,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5021,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5022,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5023,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5024,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5025,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5026,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5027,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5028,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5029,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5030,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5031,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5032,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5033,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5034,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5035,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5036,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5037,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5038,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5039,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5040,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5041,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5042,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5043,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5044,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5045,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5046,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5047,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5048,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5049,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5050,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5051,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5052,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5053,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5054,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5055,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5056,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5057,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5058,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5059,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5060,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5061,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5062,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5063,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5064,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5065,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5066,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 5067,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5068,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5069,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5070,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5071,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5072,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5073,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5074,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5075,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5076,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5077,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5078,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5079,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5080,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5081,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5082,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5083,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5084,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 5085,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5086,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5087,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 5088,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5089,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5090,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5091,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5092,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5093,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5094,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5095,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5096,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5097,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5098,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5099,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5100,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5101,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 5102,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5103,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5104,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5105,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5106,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5107,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5108,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5109,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5110,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5111,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5112,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5113,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5114,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5115,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5116,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5117,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5118,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5119,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5120,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5121,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5122,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5123,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5124,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5125,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5126,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5127,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5128,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5129,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5130,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5131,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 5132,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5133,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5134,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5135,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5136,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5137,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5138,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5139,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5140,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5141,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5142,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5143,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5144,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5145,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5146,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5147,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5148,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5149,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5150,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5151,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5152,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5153,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5154,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5155,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5156,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5157,
    'READER VARIANT': 'First Edition V14',
  },
  {
    'TOKEN ID': 5158,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5159,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5160,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5161,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5162,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5163,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5164,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5165,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5166,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5167,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5168,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5169,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5170,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5171,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5172,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5173,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5174,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5175,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5176,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5177,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5178,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5179,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5180,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5181,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5182,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5183,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5184,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5185,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5186,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5187,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5188,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5189,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5190,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5191,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5192,
    'READER VARIANT': 'First Edition V20',
  },
  {
    'TOKEN ID': 5193,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5194,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5195,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5196,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5197,
    'READER VARIANT': 'First Edition V14',
  },
  {
    'TOKEN ID': 5198,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5199,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5200,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5201,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5202,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5203,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5204,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5205,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5206,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5207,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5208,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5209,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5210,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5211,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5212,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5213,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5214,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5215,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5216,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5217,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5218,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5219,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5220,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5221,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5222,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5223,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5224,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5225,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5226,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 5227,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5228,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5229,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5230,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5231,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5232,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5233,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5234,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5235,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5236,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5237,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5238,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5239,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5240,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5241,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5242,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5243,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5244,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5245,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5246,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5247,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5248,
    'READER VARIANT': 'First Edition V18',
  },
  {
    'TOKEN ID': 5249,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5250,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5251,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5252,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5253,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5254,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5255,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5256,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5257,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5258,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5259,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5260,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5261,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5262,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5263,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5264,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5265,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5266,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5267,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5268,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5269,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5270,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5271,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5272,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5273,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5274,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5275,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5276,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5277,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5278,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5279,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5280,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 5281,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5282,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5283,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5284,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5285,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5286,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5287,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5288,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5289,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5290,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5291,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5292,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5293,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5294,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5295,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5296,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5297,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5298,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5299,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 5300,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5301,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5302,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5303,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5304,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5305,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5306,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5307,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5308,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5309,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5310,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5311,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5312,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5313,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5314,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5315,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5316,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5317,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5318,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5319,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5320,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5321,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5322,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5323,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5324,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5325,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5326,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5327,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5328,
    'READER VARIANT': 'First Edition V2',
  },
  {
    'TOKEN ID': 5329,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5330,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 5331,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5332,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5333,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5334,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5335,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5336,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5337,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5338,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5339,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5340,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5341,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5342,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5343,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5344,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5345,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5346,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5347,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5348,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5349,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5350,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5351,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5352,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5353,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5354,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5355,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5356,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5357,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5358,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5359,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5360,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5361,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5362,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5363,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5364,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5365,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5366,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5367,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5368,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5369,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5370,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5371,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5372,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5373,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5374,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5375,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5376,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5377,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5378,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5379,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5380,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5381,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5382,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5383,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5384,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5385,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5386,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5387,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5388,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5389,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5390,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5391,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5392,
    'READER VARIANT': 'First Edition V2',
  },
  {
    'TOKEN ID': 5393,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5394,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5395,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5396,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5397,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5398,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5399,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5400,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5401,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5402,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5403,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5404,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5405,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5406,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5407,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5408,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5409,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5410,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5411,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5412,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5413,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5414,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5415,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5416,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5417,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5418,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5419,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5420,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5421,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5422,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5423,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5424,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5425,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5426,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5427,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5428,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5429,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5430,
    'READER VARIANT': "Artist's Proof Edition V8",
  },
  {
    'TOKEN ID': 5431,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5432,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5433,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5434,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5435,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5436,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5437,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5438,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 5439,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5440,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5441,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5442,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5443,
    'READER VARIANT': "Artist's Proof Edition V4",
  },
  {
    'TOKEN ID': 5444,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5445,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5446,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5447,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5448,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5449,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5450,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5451,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5452,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5453,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5454,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5455,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5456,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5457,
    'READER VARIANT': "Artist's Proof Edition V16",
  },
  {
    'TOKEN ID': 5458,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5459,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5460,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5461,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5462,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5463,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5464,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5465,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5466,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5467,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5468,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5469,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5470,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5471,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5472,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5473,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5474,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5475,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5476,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5477,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5478,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5479,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5480,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5481,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5482,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5483,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5484,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5485,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5486,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5487,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5488,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5489,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5490,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5491,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5492,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5493,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5494,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5495,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5496,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5497,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5498,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5499,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5500,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5501,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5502,
    'READER VARIANT': 'First Edition V2',
  },
  {
    'TOKEN ID': 5503,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5504,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5505,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5506,
    'READER VARIANT': 'First Edition V20',
  },
  {
    'TOKEN ID': 5507,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5508,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5509,
    'READER VARIANT': 'First Edition V2',
  },
  {
    'TOKEN ID': 5510,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5511,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5512,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5513,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5514,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5515,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5516,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5517,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5518,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5519,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5520,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5521,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5522,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5523,
    'READER VARIANT': 'First Edition V8',
  },
  {
    'TOKEN ID': 5524,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5525,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5526,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5527,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5528,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5529,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5530,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5531,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5532,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5533,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5534,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5535,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5536,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5537,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5538,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5539,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5540,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5541,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5542,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5543,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5544,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5545,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5546,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5547,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5548,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5549,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5550,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5551,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5552,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 5553,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5554,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5555,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5556,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5557,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5558,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5559,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5560,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5561,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5562,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5563,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5564,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5565,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5566,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5567,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5568,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5569,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5570,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5571,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5572,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5573,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5574,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5575,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5576,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5577,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5578,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5579,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5580,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5581,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5582,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5583,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5584,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5585,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5586,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5587,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5588,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5589,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5590,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5591,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5592,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5593,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5594,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5595,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5596,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5597,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5598,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5599,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 5600,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5601,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5602,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5603,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5604,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5605,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5606,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5607,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5608,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5609,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5610,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5611,
    'READER VARIANT': 'First Edition V6',
  },
  {
    'TOKEN ID': 5612,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5613,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5614,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5615,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5616,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5617,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5618,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5619,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5620,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5621,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5622,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5623,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5624,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5625,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5626,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5627,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5628,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5629,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5630,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5631,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5632,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5633,
    'READER VARIANT': 'First Edition V18',
  },
  {
    'TOKEN ID': 5634,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5635,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5636,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5637,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5638,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5639,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5640,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5641,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 5642,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5643,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5644,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5645,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5646,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5647,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5648,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5649,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5650,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5651,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5652,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5653,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5654,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5655,
    'READER VARIANT': "Artist's Proof Edition V6",
  },
  {
    'TOKEN ID': 5656,
    'READER VARIANT': "Artist's Proof Edition V10",
  },
  {
    'TOKEN ID': 5657,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5658,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5659,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5660,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5661,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5662,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5663,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5664,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5665,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5666,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5667,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5668,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5669,
    'READER VARIANT': 'First Edition V2',
  },
  {
    'TOKEN ID': 5670,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5671,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5672,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5673,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5674,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5675,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 5676,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5677,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5678,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5679,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5680,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5681,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5682,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5683,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5684,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5685,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5686,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5687,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5688,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5689,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5690,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5691,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5692,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5693,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5694,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5695,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5696,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5697,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5698,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5699,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5700,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5701,
    'READER VARIANT': 'First Edition V20',
  },
  {
    'TOKEN ID': 5702,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5703,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5704,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5705,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5706,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5707,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5708,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5709,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5710,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5711,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5712,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5713,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 5714,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5715,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5716,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5717,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5718,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5719,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5720,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5721,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5722,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5723,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5724,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5725,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5726,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5727,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5728,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5729,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5730,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5731,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5732,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5733,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5734,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5735,
    'READER VARIANT': "Artist's Proof Edition V20",
  },
  {
    'TOKEN ID': 5736,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5737,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5738,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5739,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5740,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5741,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5742,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5743,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5744,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5745,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5746,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5747,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5748,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5749,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5750,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5751,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5752,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5753,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5754,
    'READER VARIANT': "Artist's Proof Edition V12",
  },
  {
    'TOKEN ID': 5755,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5756,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5757,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5758,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5759,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5760,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5761,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5762,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5763,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5764,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5765,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5766,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5767,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5768,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5769,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5770,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5771,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5772,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5773,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5774,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5775,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5776,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5777,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5778,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5779,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5780,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5781,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5782,
    'READER VARIANT': "Artist's Proof Edition V18",
  },
  {
    'TOKEN ID': 5783,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5784,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5785,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5786,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5787,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5788,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5789,
    'READER VARIANT': "Artist's Proof Edition V3",
  },
  {
    'TOKEN ID': 5790,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5791,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5792,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5793,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5794,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5795,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5796,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5797,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5798,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5799,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5800,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5801,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5802,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5803,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5804,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5805,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5806,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5807,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5808,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5809,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5810,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5811,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5812,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5813,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5814,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5815,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5816,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5817,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5818,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5819,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5820,
    'READER VARIANT': 'First Edition V18',
  },
  {
    'TOKEN ID': 5821,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5822,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5823,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5824,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5825,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5826,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5827,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5828,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5829,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5830,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5831,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5832,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5833,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5834,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5835,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5836,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5837,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5838,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5839,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5840,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5841,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 5842,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5843,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5844,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5845,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 5846,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5847,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5848,
    'READER VARIANT': "Artist's Proof Edition V2",
  },
  {
    'TOKEN ID': 5849,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5850,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5851,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5852,
    'READER VARIANT': 'First Edition V12',
  },
  {
    'TOKEN ID': 5853,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5854,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5855,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5856,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5857,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5858,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5859,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5860,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5861,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5862,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5863,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5864,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5865,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5866,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5867,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5868,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5869,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5870,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5871,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5872,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5873,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5874,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5875,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5876,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5877,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5878,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5879,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5880,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5881,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5882,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5883,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5884,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5885,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5886,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 5887,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5888,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5889,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5890,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5891,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5892,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5893,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5894,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5895,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5896,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5897,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5898,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5899,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5900,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5901,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5902,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 5903,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5904,
    'READER VARIANT': 'First Edition V8',
  },
  {
    'TOKEN ID': 5905,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5906,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5907,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5908,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5909,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5910,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5911,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5912,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5913,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5914,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5915,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5916,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5917,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5918,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5919,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5920,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5921,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5922,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5923,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5924,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5925,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5926,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5927,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5928,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5929,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5930,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5931,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5932,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5933,
    'READER VARIANT': "Artist's Proof Edition V15",
  },
  {
    'TOKEN ID': 5934,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5935,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5936,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5937,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5938,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5939,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5940,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5941,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5942,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 5943,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5944,
    'READER VARIANT': 'First Edition V16',
  },
  {
    'TOKEN ID': 5945,
    'READER VARIANT': 'First Edition V10',
  },
  {
    'TOKEN ID': 5946,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5947,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5948,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5949,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5950,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5951,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5952,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5953,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5954,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5955,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5956,
    'READER VARIANT': 'First Edition V8',
  },
  {
    'TOKEN ID': 5957,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5958,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5959,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5960,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5961,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 5962,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5963,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 5964,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5965,
    'READER VARIANT': "Artist's Proof Edition V19",
  },
  {
    'TOKEN ID': 5966,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5967,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5968,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5969,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 5970,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5971,
    'READER VARIANT': "Artist's Proof Edition V1",
  },
  {
    'TOKEN ID': 5972,
    'READER VARIANT': "Artist's Proof Edition V7",
  },
  {
    'TOKEN ID': 5973,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5974,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 5975,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5976,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5977,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5978,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5979,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 5980,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 5981,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5982,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5983,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 5984,
    'READER VARIANT': 'First Edition V14',
  },
  {
    'TOKEN ID': 5985,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5986,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5987,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5988,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 5989,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5990,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5991,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5992,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 5993,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5994,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5995,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 5996,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 5997,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 5998,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 5999,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6000,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6001,
    'READER VARIANT': "Artist's Proof Edition V11",
  },
  {
    'TOKEN ID': 6002,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6003,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6004,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6005,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6006,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6007,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6008,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6009,
    'READER VARIANT': "Artist's Proof Edition V17",
  },
  {
    'TOKEN ID': 6010,
    'READER VARIANT': "Artist's Proof Edition V13",
  },
  {
    'TOKEN ID': 6011,
    'READER VARIANT': "Artist's Proof Edition V5",
  },
  {
    'TOKEN ID': 6012,
    'READER VARIANT': "Artist's Proof Edition V9",
  },
  {
    'TOKEN ID': 6013,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6014,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6015,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6016,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6017,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6018,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6019,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6020,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6021,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6022,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6023,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6024,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6025,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6026,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6027,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6028,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6029,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6030,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6031,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6032,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6033,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6034,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6035,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6036,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6037,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6038,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6039,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6040,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6041,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6042,
    'READER VARIANT': 'First Edition V18',
  },
  {
    'TOKEN ID': 6043,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6044,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6045,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6046,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6047,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6048,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6049,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6050,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6051,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6052,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6053,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6054,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6055,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6056,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6057,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6058,
    'READER VARIANT': 'First Edition V2',
  },
  {
    'TOKEN ID': 6059,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6060,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6061,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6062,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6063,
    'READER VARIANT': 'First Edition V8',
  },
  {
    'TOKEN ID': 6064,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6065,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6066,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6067,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6068,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6069,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6070,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6071,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6072,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6073,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6074,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6075,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6076,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6077,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6078,
    'READER VARIANT': 'First Edition V18',
  },
  {
    'TOKEN ID': 6079,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6080,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6081,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6082,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6083,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6084,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6085,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6086,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6087,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6088,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6089,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6090,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6091,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6092,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6093,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6094,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6095,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6096,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6097,
    'READER VARIANT': 'First Edition V2',
  },
  {
    'TOKEN ID': 6098,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6099,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6100,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6101,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6102,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6103,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6104,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6105,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6106,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6107,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6108,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6109,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6110,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6111,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6112,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6113,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6114,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6115,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6116,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6117,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6118,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6119,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6120,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6121,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6122,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6123,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6124,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6125,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6126,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6127,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6128,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6129,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6130,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6131,
    'READER VARIANT': 'First Edition V4',
  },
  {
    'TOKEN ID': 6132,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6133,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6134,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6135,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6136,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6137,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6138,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6139,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6140,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6141,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6142,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6143,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6144,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6145,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6146,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6147,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6148,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6149,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6150,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6151,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6152,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6153,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6154,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6155,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6156,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6157,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6158,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6159,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6160,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6161,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6162,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6163,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6164,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6165,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6166,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6167,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6168,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6169,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6170,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6171,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6172,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6173,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6174,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6175,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6176,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6177,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6178,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6179,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6180,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6181,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6182,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6183,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6184,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6185,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6186,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6187,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6188,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6189,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6190,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6191,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6192,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6193,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6194,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6195,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6196,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6197,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6198,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6199,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6200,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6201,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6202,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6203,
    'READER VARIANT': 'First Edition V19',
  },
  {
    'TOKEN ID': 6204,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6205,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6206,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6207,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6208,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6209,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6210,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6211,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6212,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6213,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6214,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6215,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6216,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6217,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6218,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6219,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6220,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6221,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6222,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6223,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6224,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6225,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6226,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6227,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6228,
    'READER VARIANT': 'First Edition V6',
  },
  {
    'TOKEN ID': 6229,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6230,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6231,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6232,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6233,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6234,
    'READER VARIANT': 'First Edition V18',
  },
  {
    'TOKEN ID': 6235,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6236,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6237,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6238,
    'READER VARIANT': 'First Edition V3',
  },
  {
    'TOKEN ID': 6239,
    'READER VARIANT': 'First Edition V13',
  },
  {
    'TOKEN ID': 6240,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6241,
    'READER VARIANT': 'First Edition V11',
  },
  {
    'TOKEN ID': 6242,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6243,
    'READER VARIANT': 'First Edition V17',
  },
  {
    'TOKEN ID': 6244,
    'READER VARIANT': 'First Edition V15',
  },
  {
    'TOKEN ID': 6245,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6246,
    'READER VARIANT': 'First Edition V9',
  },
  {
    'TOKEN ID': 6247,
    'READER VARIANT': 'First Edition V5',
  },
  {
    'TOKEN ID': 6248,
    'READER VARIANT': 'First Edition V1',
  },
  {
    'TOKEN ID': 6249,
    'READER VARIANT': 'First Edition V7',
  },
  {
    'TOKEN ID': 6250,
    'READER VARIANT': 'First Edition V19',
  },
];

export const TOKEN_DISTRIBUTION = [
  {
    "File Name": "eonRift_i1-p03.png",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": 1,
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": 1,
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": 1,
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": 1,
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": 1,
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": 1,
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": 1,
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": 1,
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": 1,
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": 1,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": "-",
    "First Edition V2": 1,
    "First Edition V3": "-",
    "First Edition V4": 1,
    "First Edition V5": "-",
    "First Edition V6": 1,
    "First Edition V7": "-",
    "First Edition V8": 1,
    "First Edition V9": "-",
    "First Edition V10": 1,
    "First Edition V11": "-",
    "First Edition V12": 1,
    "First Edition V13": "-",
    "First Edition V14": 1,
    "First Edition V15": "-",
    "First Edition V16": 1,
    "First Edition V17": "-",
    "First Edition V18": 1,
    "First Edition V19": "-",
    "First Edition V20": 1,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p03.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": 1,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": 1
  },
  {
    "File Name": "eonRift_i1-p02.png",
    "Artist's Proof Edition V1": 1,
    "Artist's Proof Edition V2": 2,
    "Artist's Proof Edition V3": 1,
    "Artist's Proof Edition V4": 2,
    "Artist's Proof Edition V5": 1,
    "Artist's Proof Edition V6": 2,
    "Artist's Proof Edition V7": 1,
    "Artist's Proof Edition V8": 2,
    "Artist's Proof Edition V9": 1,
    "Artist's Proof Edition V10": 2,
    "Artist's Proof Edition V11": 1,
    "Artist's Proof Edition V12": 2,
    "Artist's Proof Edition V13": 1,
    "Artist's Proof Edition V14": 2,
    "Artist's Proof Edition V15": 1,
    "Artist's Proof Edition V16": 2,
    "Artist's Proof Edition V17": 1,
    "Artist's Proof Edition V18": 2,
    "Artist's Proof Edition V19": 1,
    "Artist's Proof Edition V20": 2,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p02.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 1,
    "Artist's Proof Edition V22": 2,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p01.png",
    "Artist's Proof Edition V1": 2,
    "Artist's Proof Edition V2": 3,
    "Artist's Proof Edition V3": 2,
    "Artist's Proof Edition V4": 3,
    "Artist's Proof Edition V5": 2,
    "Artist's Proof Edition V6": 3,
    "Artist's Proof Edition V7": 2,
    "Artist's Proof Edition V8": 3,
    "Artist's Proof Edition V9": 2,
    "Artist's Proof Edition V10": 3,
    "Artist's Proof Edition V11": 2,
    "Artist's Proof Edition V12": 3,
    "Artist's Proof Edition V13": 2,
    "Artist's Proof Edition V14": 3,
    "Artist's Proof Edition V15": 2,
    "Artist's Proof Edition V16": 3,
    "Artist's Proof Edition V17": 2,
    "Artist's Proof Edition V18": 3,
    "Artist's Proof Edition V19": 2,
    "Artist's Proof Edition V20": 3,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 1,
    "First Edition V2": 2,
    "First Edition V3": 1,
    "First Edition V4": 2,
    "First Edition V5": 1,
    "First Edition V6": 2,
    "First Edition V7": 1,
    "First Edition V8": 2,
    "First Edition V9": 1,
    "First Edition V10": 2,
    "First Edition V11": 1,
    "First Edition V12": 2,
    "First Edition V13": 1,
    "First Edition V14": 2,
    "First Edition V15": 1,
    "First Edition V16": 2,
    "First Edition V17": 1,
    "First Edition V18": 2,
    "First Edition V19": 1,
    "First Edition V20": 2,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p01.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 2,
    "Artist's Proof Edition V22": 3,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 1,
    "First Edition V22": 2
  },
  {
    "File Name": "eonRift_i1-p04.png",
    "Artist's Proof Edition V1": 3,
    "Artist's Proof Edition V2": 4,
    "Artist's Proof Edition V3": 3,
    "Artist's Proof Edition V4": 4,
    "Artist's Proof Edition V5": 3,
    "Artist's Proof Edition V6": 4,
    "Artist's Proof Edition V7": 3,
    "Artist's Proof Edition V8": 4,
    "Artist's Proof Edition V9": 3,
    "Artist's Proof Edition V10": 4,
    "Artist's Proof Edition V11": 3,
    "Artist's Proof Edition V12": 4,
    "Artist's Proof Edition V13": 3,
    "Artist's Proof Edition V14": 4,
    "Artist's Proof Edition V15": 3,
    "Artist's Proof Edition V16": 4,
    "Artist's Proof Edition V17": 3,
    "Artist's Proof Edition V18": 4,
    "Artist's Proof Edition V19": 3,
    "Artist's Proof Edition V20": 4,
    "Artist's Proof Edition V21": 3,
    "Artist's Proof Edition V22": 4,
    "First Edition V1": 2,
    "First Edition V2": 3,
    "First Edition V3": 2,
    "First Edition V4": 3,
    "First Edition V5": 2,
    "First Edition V6": 3,
    "First Edition V7": 2,
    "First Edition V8": 3,
    "First Edition V9": 2,
    "First Edition V10": 3,
    "First Edition V11": 2,
    "First Edition V12": 3,
    "First Edition V13": 2,
    "First Edition V14": 3,
    "First Edition V15": 2,
    "First Edition V16": 3,
    "First Edition V17": 2,
    "First Edition V18": 3,
    "First Edition V19": 2,
    "First Edition V20": 3,
    "First Edition V21": 2,
    "First Edition V22": 3
  },
  {
    "File Name": "eonRift_i1-p05.png",
    "Artist's Proof Edition V1": 4,
    "Artist's Proof Edition V2": 5,
    "Artist's Proof Edition V3": 4,
    "Artist's Proof Edition V4": 5,
    "Artist's Proof Edition V5": 4,
    "Artist's Proof Edition V6": 5,
    "Artist's Proof Edition V7": 4,
    "Artist's Proof Edition V8": 5,
    "Artist's Proof Edition V9": 4,
    "Artist's Proof Edition V10": 5,
    "Artist's Proof Edition V11": 4,
    "Artist's Proof Edition V12": 5,
    "Artist's Proof Edition V13": 4,
    "Artist's Proof Edition V14": 5,
    "Artist's Proof Edition V15": 4,
    "Artist's Proof Edition V16": 5,
    "Artist's Proof Edition V17": 4,
    "Artist's Proof Edition V18": 5,
    "Artist's Proof Edition V19": 4,
    "Artist's Proof Edition V20": 5,
    "Artist's Proof Edition V21": 4,
    "Artist's Proof Edition V22": 5,
    "First Edition V1": 3,
    "First Edition V2": 4,
    "First Edition V3": 3,
    "First Edition V4": 4,
    "First Edition V5": 3,
    "First Edition V6": 4,
    "First Edition V7": 3,
    "First Edition V8": 4,
    "First Edition V9": 3,
    "First Edition V10": 4,
    "First Edition V11": 3,
    "First Edition V12": 4,
    "First Edition V13": 3,
    "First Edition V14": 4,
    "First Edition V15": 3,
    "First Edition V16": 4,
    "First Edition V17": 3,
    "First Edition V18": 4,
    "First Edition V19": 3,
    "First Edition V20": 4,
    "First Edition V21": 3,
    "First Edition V22": 4
  },
  {
    "File Name": "eonRift_i1-p06.png",
    "Artist's Proof Edition V1": 5,
    "Artist's Proof Edition V2": 6,
    "Artist's Proof Edition V3": 5,
    "Artist's Proof Edition V4": 6,
    "Artist's Proof Edition V5": 5,
    "Artist's Proof Edition V6": 6,
    "Artist's Proof Edition V7": 5,
    "Artist's Proof Edition V8": 6,
    "Artist's Proof Edition V9": 5,
    "Artist's Proof Edition V10": 6,
    "Artist's Proof Edition V11": 5,
    "Artist's Proof Edition V12": 6,
    "Artist's Proof Edition V13": 5,
    "Artist's Proof Edition V14": 6,
    "Artist's Proof Edition V15": 5,
    "Artist's Proof Edition V16": 6,
    "Artist's Proof Edition V17": 5,
    "Artist's Proof Edition V18": 6,
    "Artist's Proof Edition V19": 5,
    "Artist's Proof Edition V20": 6,
    "Artist's Proof Edition V21": 5,
    "Artist's Proof Edition V22": 6,
    "First Edition V1": 4,
    "First Edition V2": 5,
    "First Edition V3": 4,
    "First Edition V4": 5,
    "First Edition V5": 4,
    "First Edition V6": 5,
    "First Edition V7": 4,
    "First Edition V8": 5,
    "First Edition V9": 4,
    "First Edition V10": 5,
    "First Edition V11": 4,
    "First Edition V12": 5,
    "First Edition V13": 4,
    "First Edition V14": 5,
    "First Edition V15": 4,
    "First Edition V16": 5,
    "First Edition V17": 4,
    "First Edition V18": 5,
    "First Edition V19": 4,
    "First Edition V20": 5,
    "First Edition V21": 4,
    "First Edition V22": 5
  },
  {
    "File Name": "eonRift_i1-p07.mp4",
    "Artist's Proof Edition V1": 6,
    "Artist's Proof Edition V2": 7,
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 6,
    "Artist's Proof Edition V22": 7,
    "First Edition V1": 5,
    "First Edition V2": 6,
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 5,
    "First Edition V22": 6
  },
  {
    "File Name": "eonRift_i1-p07.png",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": 6,
    "Artist's Proof Edition V4": 7,
    "Artist's Proof Edition V5": 6,
    "Artist's Proof Edition V6": 7,
    "Artist's Proof Edition V7": 6,
    "Artist's Proof Edition V8": 7,
    "Artist's Proof Edition V9": 6,
    "Artist's Proof Edition V10": 7,
    "Artist's Proof Edition V11": 6,
    "Artist's Proof Edition V12": 7,
    "Artist's Proof Edition V13": 6,
    "Artist's Proof Edition V14": 7,
    "Artist's Proof Edition V15": 6,
    "Artist's Proof Edition V16": 7,
    "Artist's Proof Edition V17": 6,
    "Artist's Proof Edition V18": 7,
    "Artist's Proof Edition V19": 6,
    "Artist's Proof Edition V20": 7,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": 5,
    "First Edition V4": 6,
    "First Edition V5": 5,
    "First Edition V6": 6,
    "First Edition V7": 5,
    "First Edition V8": 6,
    "First Edition V9": 5,
    "First Edition V10": 6,
    "First Edition V11": 5,
    "First Edition V12": 6,
    "First Edition V13": 5,
    "First Edition V14": 6,
    "First Edition V15": 5,
    "First Edition V16": 6,
    "First Edition V17": 5,
    "First Edition V18": 6,
    "First Edition V19": 5,
    "First Edition V20": 6,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p08.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": 7,
    "Artist's Proof Edition V4": 8,
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 7,
    "Artist's Proof Edition V22": 8,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": 6,
    "First Edition V4": 7,
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 6,
    "First Edition V22": 7
  },
  {
    "File Name": "eonRift_i1-p08.png",
    "Artist's Proof Edition V1": 7,
    "Artist's Proof Edition V2": 8,
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": 7,
    "Artist's Proof Edition V6": 8,
    "Artist's Proof Edition V7": 7,
    "Artist's Proof Edition V8": 8,
    "Artist's Proof Edition V9": 7,
    "Artist's Proof Edition V10": 8,
    "Artist's Proof Edition V11": 7,
    "Artist's Proof Edition V12": 8,
    "Artist's Proof Edition V13": 7,
    "Artist's Proof Edition V14": 8,
    "Artist's Proof Edition V15": 7,
    "Artist's Proof Edition V16": 8,
    "Artist's Proof Edition V17": 7,
    "Artist's Proof Edition V18": 8,
    "Artist's Proof Edition V19": 7,
    "Artist's Proof Edition V20": 8,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 6,
    "First Edition V2": 7,
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": 6,
    "First Edition V6": 7,
    "First Edition V7": 6,
    "First Edition V8": 7,
    "First Edition V9": 6,
    "First Edition V10": 7,
    "First Edition V11": 6,
    "First Edition V12": 7,
    "First Edition V13": 6,
    "First Edition V14": 7,
    "First Edition V15": 6,
    "First Edition V16": 7,
    "First Edition V17": 6,
    "First Edition V18": 7,
    "First Edition V19": 6,
    "First Edition V20": 7,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p09.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": 8,
    "Artist's Proof Edition V6": 9,
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 8,
    "Artist's Proof Edition V22": 9,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": 7,
    "First Edition V6": 8,
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 7,
    "First Edition V22": 8
  },
  {
    "File Name": "eonRift_i1-p09.png",
    "Artist's Proof Edition V1": 8,
    "Artist's Proof Edition V2": 9,
    "Artist's Proof Edition V3": 8,
    "Artist's Proof Edition V4": 9,
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": 8,
    "Artist's Proof Edition V8": 9,
    "Artist's Proof Edition V9": 8,
    "Artist's Proof Edition V10": 9,
    "Artist's Proof Edition V11": 8,
    "Artist's Proof Edition V12": 9,
    "Artist's Proof Edition V13": 8,
    "Artist's Proof Edition V14": 9,
    "Artist's Proof Edition V15": 8,
    "Artist's Proof Edition V16": 9,
    "Artist's Proof Edition V17": 8,
    "Artist's Proof Edition V18": 9,
    "Artist's Proof Edition V19": 8,
    "Artist's Proof Edition V20": 9,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 7,
    "First Edition V2": 8,
    "First Edition V3": 7,
    "First Edition V4": 8,
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": 7,
    "First Edition V8": 8,
    "First Edition V9": 7,
    "First Edition V10": 8,
    "First Edition V11": 7,
    "First Edition V12": 8,
    "First Edition V13": 7,
    "First Edition V14": 8,
    "First Edition V15": 7,
    "First Edition V16": 8,
    "First Edition V17": 7,
    "First Edition V18": 8,
    "First Edition V19": 7,
    "First Edition V20": 8,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p10.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": 9,
    "Artist's Proof Edition V8": 10,
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 9,
    "Artist's Proof Edition V22": 10,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": 8,
    "First Edition V8": 9,
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 8,
    "First Edition V22": 9
  },
  {
    "File Name": "eonRift_i1-p10.png",
    "Artist's Proof Edition V1": 9,
    "Artist's Proof Edition V2": 10,
    "Artist's Proof Edition V3": 9,
    "Artist's Proof Edition V4": 10,
    "Artist's Proof Edition V5": 9,
    "Artist's Proof Edition V6": 10,
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": 9,
    "Artist's Proof Edition V10": 10,
    "Artist's Proof Edition V11": 9,
    "Artist's Proof Edition V12": 10,
    "Artist's Proof Edition V13": 9,
    "Artist's Proof Edition V14": 10,
    "Artist's Proof Edition V15": 9,
    "Artist's Proof Edition V16": 10,
    "Artist's Proof Edition V17": 9,
    "Artist's Proof Edition V18": 10,
    "Artist's Proof Edition V19": 9,
    "Artist's Proof Edition V20": 10,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 8,
    "First Edition V2": 9,
    "First Edition V3": 8,
    "First Edition V4": 9,
    "First Edition V5": 8,
    "First Edition V6": 9,
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": 8,
    "First Edition V10": 9,
    "First Edition V11": 8,
    "First Edition V12": 9,
    "First Edition V13": 8,
    "First Edition V14": 9,
    "First Edition V15": 8,
    "First Edition V16": 9,
    "First Edition V17": 8,
    "First Edition V18": 9,
    "First Edition V19": 8,
    "First Edition V20": 9,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p11.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": 10,
    "Artist's Proof Edition V10": 11,
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 10,
    "Artist's Proof Edition V22": 11,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": 9,
    "First Edition V10": 10,
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 9,
    "First Edition V22": 10
  },
  {
    "File Name": "eonRift_i1-p11.png",
    "Artist's Proof Edition V1": 10,
    "Artist's Proof Edition V2": 11,
    "Artist's Proof Edition V3": 10,
    "Artist's Proof Edition V4": 11,
    "Artist's Proof Edition V5": 10,
    "Artist's Proof Edition V6": 11,
    "Artist's Proof Edition V7": 10,
    "Artist's Proof Edition V8": 11,
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": 10,
    "Artist's Proof Edition V12": 11,
    "Artist's Proof Edition V13": 10,
    "Artist's Proof Edition V14": 11,
    "Artist's Proof Edition V15": 10,
    "Artist's Proof Edition V16": 11,
    "Artist's Proof Edition V17": 10,
    "Artist's Proof Edition V18": 11,
    "Artist's Proof Edition V19": 10,
    "Artist's Proof Edition V20": 11,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 9,
    "First Edition V2": 10,
    "First Edition V3": 9,
    "First Edition V4": 10,
    "First Edition V5": 9,
    "First Edition V6": 10,
    "First Edition V7": 9,
    "First Edition V8": 10,
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": 9,
    "First Edition V12": 10,
    "First Edition V13": 9,
    "First Edition V14": 10,
    "First Edition V15": 9,
    "First Edition V16": 10,
    "First Edition V17": 9,
    "First Edition V18": 10,
    "First Edition V19": 9,
    "First Edition V20": 10,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p12.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": 11,
    "Artist's Proof Edition V12": 12,
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 11,
    "Artist's Proof Edition V22": 12,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": 10,
    "First Edition V12": 11,
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 10,
    "First Edition V22": 11
  },
  {
    "File Name": "eonRift_i1-p12.png",
    "Artist's Proof Edition V1": 11,
    "Artist's Proof Edition V2": 12,
    "Artist's Proof Edition V3": 11,
    "Artist's Proof Edition V4": 12,
    "Artist's Proof Edition V5": 11,
    "Artist's Proof Edition V6": 12,
    "Artist's Proof Edition V7": 11,
    "Artist's Proof Edition V8": 12,
    "Artist's Proof Edition V9": 11,
    "Artist's Proof Edition V10": 12,
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": 11,
    "Artist's Proof Edition V14": 12,
    "Artist's Proof Edition V15": 11,
    "Artist's Proof Edition V16": 12,
    "Artist's Proof Edition V17": 11,
    "Artist's Proof Edition V18": 12,
    "Artist's Proof Edition V19": 11,
    "Artist's Proof Edition V20": 12,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 10,
    "First Edition V2": 11,
    "First Edition V3": 10,
    "First Edition V4": 11,
    "First Edition V5": 10,
    "First Edition V6": 11,
    "First Edition V7": 10,
    "First Edition V8": 11,
    "First Edition V9": 10,
    "First Edition V10": 11,
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": 10,
    "First Edition V14": 11,
    "First Edition V15": 10,
    "First Edition V16": 11,
    "First Edition V17": 10,
    "First Edition V18": 11,
    "First Edition V19": 10,
    "First Edition V20": 11,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p13.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": 12,
    "Artist's Proof Edition V14": 13,
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 12,
    "Artist's Proof Edition V22": 13,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": 11,
    "First Edition V14": 12,
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 11,
    "First Edition V22": 12
  },
  {
    "File Name": "eonRift_i1-p13.png",
    "Artist's Proof Edition V1": 12,
    "Artist's Proof Edition V2": 13,
    "Artist's Proof Edition V3": 12,
    "Artist's Proof Edition V4": 13,
    "Artist's Proof Edition V5": 12,
    "Artist's Proof Edition V6": 13,
    "Artist's Proof Edition V7": 12,
    "Artist's Proof Edition V8": 13,
    "Artist's Proof Edition V9": 12,
    "Artist's Proof Edition V10": 13,
    "Artist's Proof Edition V11": 12,
    "Artist's Proof Edition V12": 13,
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": 12,
    "Artist's Proof Edition V16": 13,
    "Artist's Proof Edition V17": 12,
    "Artist's Proof Edition V18": 13,
    "Artist's Proof Edition V19": 12,
    "Artist's Proof Edition V20": 13,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 11,
    "First Edition V2": 12,
    "First Edition V3": 11,
    "First Edition V4": 12,
    "First Edition V5": 11,
    "First Edition V6": 12,
    "First Edition V7": 11,
    "First Edition V8": 12,
    "First Edition V9": 11,
    "First Edition V10": 12,
    "First Edition V11": 11,
    "First Edition V12": 12,
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": 11,
    "First Edition V16": 12,
    "First Edition V17": 11,
    "First Edition V18": 12,
    "First Edition V19": 11,
    "First Edition V20": 12,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p14.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": 13,
    "Artist's Proof Edition V16": 14,
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 13,
    "Artist's Proof Edition V22": 14,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": 12,
    "First Edition V16": 13,
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 12,
    "First Edition V22": 13
  },
  {
    "File Name": "eonRift_i1-p14.png",
    "Artist's Proof Edition V1": 13,
    "Artist's Proof Edition V2": 14,
    "Artist's Proof Edition V3": 13,
    "Artist's Proof Edition V4": 14,
    "Artist's Proof Edition V5": 13,
    "Artist's Proof Edition V6": 14,
    "Artist's Proof Edition V7": 13,
    "Artist's Proof Edition V8": 14,
    "Artist's Proof Edition V9": 13,
    "Artist's Proof Edition V10": 14,
    "Artist's Proof Edition V11": 13,
    "Artist's Proof Edition V12": 14,
    "Artist's Proof Edition V13": 13,
    "Artist's Proof Edition V14": 14,
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": 13,
    "Artist's Proof Edition V18": 14,
    "Artist's Proof Edition V19": 13,
    "Artist's Proof Edition V20": 14,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 12,
    "First Edition V2": 13,
    "First Edition V3": 12,
    "First Edition V4": 13,
    "First Edition V5": 12,
    "First Edition V6": 13,
    "First Edition V7": 12,
    "First Edition V8": 13,
    "First Edition V9": 12,
    "First Edition V10": 13,
    "First Edition V11": 12,
    "First Edition V12": 13,
    "First Edition V13": 12,
    "First Edition V14": 13,
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": 12,
    "First Edition V18": 13,
    "First Edition V19": 12,
    "First Edition V20": 13,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p15.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": 14,
    "Artist's Proof Edition V18": 15,
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 14,
    "Artist's Proof Edition V22": 15,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": 13,
    "First Edition V18": 14,
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 13,
    "First Edition V22": 14
  },
  {
    "File Name": "eonRift_i1-p15.png",
    "Artist's Proof Edition V1": 14,
    "Artist's Proof Edition V2": 15,
    "Artist's Proof Edition V3": 14,
    "Artist's Proof Edition V4": 15,
    "Artist's Proof Edition V5": 14,
    "Artist's Proof Edition V6": 15,
    "Artist's Proof Edition V7": 14,
    "Artist's Proof Edition V8": 15,
    "Artist's Proof Edition V9": 14,
    "Artist's Proof Edition V10": 15,
    "Artist's Proof Edition V11": 14,
    "Artist's Proof Edition V12": 15,
    "Artist's Proof Edition V13": 14,
    "Artist's Proof Edition V14": 15,
    "Artist's Proof Edition V15": 14,
    "Artist's Proof Edition V16": 15,
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": 14,
    "Artist's Proof Edition V20": 15,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 13,
    "First Edition V2": 14,
    "First Edition V3": 13,
    "First Edition V4": 14,
    "First Edition V5": 13,
    "First Edition V6": 14,
    "First Edition V7": 13,
    "First Edition V8": 14,
    "First Edition V9": 13,
    "First Edition V10": 14,
    "First Edition V11": 13,
    "First Edition V12": 14,
    "First Edition V13": 13,
    "First Edition V14": 14,
    "First Edition V15": 13,
    "First Edition V16": 14,
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": 13,
    "First Edition V20": 14,
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p16.mp4",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": 15,
    "Artist's Proof Edition V20": 16,
    "Artist's Proof Edition V21": 15,
    "Artist's Proof Edition V22": 16,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": 14,
    "First Edition V20": 15,
    "First Edition V21": 14,
    "First Edition V22": 15
  },
  {
    "File Name": "eonRift_i1-p16.png",
    "Artist's Proof Edition V1": 15,
    "Artist's Proof Edition V2": 16,
    "Artist's Proof Edition V3": 15,
    "Artist's Proof Edition V4": 16,
    "Artist's Proof Edition V5": 15,
    "Artist's Proof Edition V6": 16,
    "Artist's Proof Edition V7": 15,
    "Artist's Proof Edition V8": 16,
    "Artist's Proof Edition V9": 15,
    "Artist's Proof Edition V10": 16,
    "Artist's Proof Edition V11": 15,
    "Artist's Proof Edition V12": 16,
    "Artist's Proof Edition V13": 15,
    "Artist's Proof Edition V14": 16,
    "Artist's Proof Edition V15": 15,
    "Artist's Proof Edition V16": 16,
    "Artist's Proof Edition V17": 15,
    "Artist's Proof Edition V18": 16,
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": "-",
    "First Edition V1": 14,
    "First Edition V2": 15,
    "First Edition V3": 14,
    "First Edition V4": 15,
    "First Edition V5": 14,
    "First Edition V6": 15,
    "First Edition V7": 14,
    "First Edition V8": 15,
    "First Edition V9": 14,
    "First Edition V10": 15,
    "First Edition V11": 14,
    "First Edition V12": 15,
    "First Edition V13": 14,
    "First Edition V14": 15,
    "First Edition V15": 14,
    "First Edition V16": 15,
    "First Edition V17": 14,
    "First Edition V18": 15,
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p17.png",
    "Artist's Proof Edition V1": 16,
    "Artist's Proof Edition V2": 17,
    "Artist's Proof Edition V3": 16,
    "Artist's Proof Edition V4": 17,
    "Artist's Proof Edition V5": 16,
    "Artist's Proof Edition V6": 17,
    "Artist's Proof Edition V7": 16,
    "Artist's Proof Edition V8": 17,
    "Artist's Proof Edition V9": 16,
    "Artist's Proof Edition V10": 17,
    "Artist's Proof Edition V11": 16,
    "Artist's Proof Edition V12": 17,
    "Artist's Proof Edition V13": 16,
    "Artist's Proof Edition V14": 17,
    "Artist's Proof Edition V15": 16,
    "Artist's Proof Edition V16": 17,
    "Artist's Proof Edition V17": 16,
    "Artist's Proof Edition V18": 17,
    "Artist's Proof Edition V19": 16,
    "Artist's Proof Edition V20": 17,
    "Artist's Proof Edition V21": 16,
    "Artist's Proof Edition V22": 17,
    "First Edition V1": 15,
    "First Edition V2": 16,
    "First Edition V3": 15,
    "First Edition V4": 16,
    "First Edition V5": 15,
    "First Edition V6": 16,
    "First Edition V7": 15,
    "First Edition V8": 16,
    "First Edition V9": 15,
    "First Edition V10": 16,
    "First Edition V11": 15,
    "First Edition V12": 16,
    "First Edition V13": 15,
    "First Edition V14": 16,
    "First Edition V15": 15,
    "First Edition V16": 16,
    "First Edition V17": 15,
    "First Edition V18": 16,
    "First Edition V19": 15,
    "First Edition V20": 16,
    "First Edition V21": 15,
    "First Edition V22": 16
  },
  {
    "File Name": "eonRift_i1-p18.png",
    "Artist's Proof Edition V1": 17,
    "Artist's Proof Edition V2": 18,
    "Artist's Proof Edition V3": 17,
    "Artist's Proof Edition V4": 18,
    "Artist's Proof Edition V5": 17,
    "Artist's Proof Edition V6": 18,
    "Artist's Proof Edition V7": 17,
    "Artist's Proof Edition V8": 18,
    "Artist's Proof Edition V9": 17,
    "Artist's Proof Edition V10": 18,
    "Artist's Proof Edition V11": 17,
    "Artist's Proof Edition V12": 18,
    "Artist's Proof Edition V13": 17,
    "Artist's Proof Edition V14": 18,
    "Artist's Proof Edition V15": 17,
    "Artist's Proof Edition V16": 18,
    "Artist's Proof Edition V17": 17,
    "Artist's Proof Edition V18": 18,
    "Artist's Proof Edition V19": 17,
    "Artist's Proof Edition V20": 18,
    "Artist's Proof Edition V21": 17,
    "Artist's Proof Edition V22": 18,
    "First Edition V1": 16,
    "First Edition V2": 17,
    "First Edition V3": 16,
    "First Edition V4": 17,
    "First Edition V5": 16,
    "First Edition V6": 17,
    "First Edition V7": 16,
    "First Edition V8": 17,
    "First Edition V9": 16,
    "First Edition V10": 17,
    "First Edition V11": 16,
    "First Edition V12": 17,
    "First Edition V13": 16,
    "First Edition V14": 17,
    "First Edition V15": 16,
    "First Edition V16": 17,
    "First Edition V17": 16,
    "First Edition V18": 17,
    "First Edition V19": 16,
    "First Edition V20": 17,
    "First Edition V21": 16,
    "First Edition V22": 17
  },
  {
    "File Name": "eonRift_i1-p19.png",
    "Artist's Proof Edition V1": 18,
    "Artist's Proof Edition V2": 19,
    "Artist's Proof Edition V3": 18,
    "Artist's Proof Edition V4": 19,
    "Artist's Proof Edition V5": 18,
    "Artist's Proof Edition V6": 19,
    "Artist's Proof Edition V7": 18,
    "Artist's Proof Edition V8": 19,
    "Artist's Proof Edition V9": 18,
    "Artist's Proof Edition V10": 19,
    "Artist's Proof Edition V11": 18,
    "Artist's Proof Edition V12": 19,
    "Artist's Proof Edition V13": 18,
    "Artist's Proof Edition V14": 19,
    "Artist's Proof Edition V15": 18,
    "Artist's Proof Edition V16": 19,
    "Artist's Proof Edition V17": 18,
    "Artist's Proof Edition V18": 19,
    "Artist's Proof Edition V19": 18,
    "Artist's Proof Edition V20": 19,
    "Artist's Proof Edition V21": 18,
    "Artist's Proof Edition V22": 19,
    "First Edition V1": 17,
    "First Edition V2": 18,
    "First Edition V3": 17,
    "First Edition V4": 18,
    "First Edition V5": 17,
    "First Edition V6": 18,
    "First Edition V7": 17,
    "First Edition V8": 18,
    "First Edition V9": 17,
    "First Edition V10": 18,
    "First Edition V11": 17,
    "First Edition V12": 18,
    "First Edition V13": 17,
    "First Edition V14": 18,
    "First Edition V15": 17,
    "First Edition V16": 18,
    "First Edition V17": 17,
    "First Edition V18": 18,
    "First Edition V19": 17,
    "First Edition V20": 18,
    "First Edition V21": 17,
    "First Edition V22": 18
  },
  {
    "File Name": "eonRift_i1-p20.png",
    "Artist's Proof Edition V1": 19,
    "Artist's Proof Edition V2": 20,
    "Artist's Proof Edition V3": 19,
    "Artist's Proof Edition V4": 20,
    "Artist's Proof Edition V5": 19,
    "Artist's Proof Edition V6": 20,
    "Artist's Proof Edition V7": 19,
    "Artist's Proof Edition V8": 20,
    "Artist's Proof Edition V9": 19,
    "Artist's Proof Edition V10": 20,
    "Artist's Proof Edition V11": 19,
    "Artist's Proof Edition V12": 20,
    "Artist's Proof Edition V13": 19,
    "Artist's Proof Edition V14": 20,
    "Artist's Proof Edition V15": 19,
    "Artist's Proof Edition V16": 20,
    "Artist's Proof Edition V17": 19,
    "Artist's Proof Edition V18": 20,
    "Artist's Proof Edition V19": 19,
    "Artist's Proof Edition V20": 20,
    "Artist's Proof Edition V21": 19,
    "Artist's Proof Edition V22": 20,
    "First Edition V1": 18,
    "First Edition V2": 19,
    "First Edition V3": 18,
    "First Edition V4": 19,
    "First Edition V5": 18,
    "First Edition V6": 19,
    "First Edition V7": 18,
    "First Edition V8": 19,
    "First Edition V9": 18,
    "First Edition V10": 19,
    "First Edition V11": 18,
    "First Edition V12": 19,
    "First Edition V13": 18,
    "First Edition V14": 19,
    "First Edition V15": 18,
    "First Edition V16": 19,
    "First Edition V17": 18,
    "First Edition V18": 19,
    "First Edition V19": 18,
    "First Edition V20": 19,
    "First Edition V21": 18,
    "First Edition V22": 19
  },
  {
    "File Name": "eonRift_i1-p21.png",
    "Artist's Proof Edition V1": 20,
    "Artist's Proof Edition V2": 21,
    "Artist's Proof Edition V3": 20,
    "Artist's Proof Edition V4": 21,
    "Artist's Proof Edition V5": 20,
    "Artist's Proof Edition V6": 21,
    "Artist's Proof Edition V7": 20,
    "Artist's Proof Edition V8": 21,
    "Artist's Proof Edition V9": 20,
    "Artist's Proof Edition V10": 21,
    "Artist's Proof Edition V11": 20,
    "Artist's Proof Edition V12": 21,
    "Artist's Proof Edition V13": 20,
    "Artist's Proof Edition V14": 21,
    "Artist's Proof Edition V15": 20,
    "Artist's Proof Edition V16": 21,
    "Artist's Proof Edition V17": 20,
    "Artist's Proof Edition V18": 21,
    "Artist's Proof Edition V19": 20,
    "Artist's Proof Edition V20": 21,
    "Artist's Proof Edition V21": 20,
    "Artist's Proof Edition V22": 21,
    "First Edition V1": 19,
    "First Edition V2": 20,
    "First Edition V3": 19,
    "First Edition V4": 20,
    "First Edition V5": 19,
    "First Edition V6": 20,
    "First Edition V7": 19,
    "First Edition V8": 20,
    "First Edition V9": 19,
    "First Edition V10": 20,
    "First Edition V11": 19,
    "First Edition V12": 20,
    "First Edition V13": 19,
    "First Edition V14": 20,
    "First Edition V15": 19,
    "First Edition V16": 20,
    "First Edition V17": 19,
    "First Edition V18": 20,
    "First Edition V19": 19,
    "First Edition V20": 20,
    "First Edition V21": 19,
    "First Edition V22": 20
  },
  {
    "File Name": "eonRift_i1-p22.png",
    "Artist's Proof Edition V1": 21,
    "Artist's Proof Edition V2": 22,
    "Artist's Proof Edition V3": 21,
    "Artist's Proof Edition V4": 22,
    "Artist's Proof Edition V5": 21,
    "Artist's Proof Edition V6": 22,
    "Artist's Proof Edition V7": 21,
    "Artist's Proof Edition V8": 22,
    "Artist's Proof Edition V9": 21,
    "Artist's Proof Edition V10": 22,
    "Artist's Proof Edition V11": 21,
    "Artist's Proof Edition V12": 22,
    "Artist's Proof Edition V13": 21,
    "Artist's Proof Edition V14": 22,
    "Artist's Proof Edition V15": 21,
    "Artist's Proof Edition V16": 22,
    "Artist's Proof Edition V17": 21,
    "Artist's Proof Edition V18": 22,
    "Artist's Proof Edition V19": 21,
    "Artist's Proof Edition V20": 22,
    "Artist's Proof Edition V21": 21,
    "Artist's Proof Edition V22": 22,
    "First Edition V1": 20,
    "First Edition V2": 21,
    "First Edition V3": 20,
    "First Edition V4": 21,
    "First Edition V5": 20,
    "First Edition V6": 21,
    "First Edition V7": 20,
    "First Edition V8": 21,
    "First Edition V9": 20,
    "First Edition V10": 21,
    "First Edition V11": 20,
    "First Edition V12": 21,
    "First Edition V13": 20,
    "First Edition V14": 21,
    "First Edition V15": 20,
    "First Edition V16": 21,
    "First Edition V17": 20,
    "First Edition V18": 21,
    "First Edition V19": 20,
    "First Edition V20": 21,
    "First Edition V21": 20,
    "First Edition V22": 21
  },
  {
    "File Name": "eonRift_i1-p23.png",
    "Artist's Proof Edition V1": 22,
    "Artist's Proof Edition V2": 23,
    "Artist's Proof Edition V3": 22,
    "Artist's Proof Edition V4": 23,
    "Artist's Proof Edition V5": 22,
    "Artist's Proof Edition V6": 23,
    "Artist's Proof Edition V7": 22,
    "Artist's Proof Edition V8": 23,
    "Artist's Proof Edition V9": 22,
    "Artist's Proof Edition V10": 23,
    "Artist's Proof Edition V11": 22,
    "Artist's Proof Edition V12": 23,
    "Artist's Proof Edition V13": 22,
    "Artist's Proof Edition V14": 23,
    "Artist's Proof Edition V15": 22,
    "Artist's Proof Edition V16": 23,
    "Artist's Proof Edition V17": 22,
    "Artist's Proof Edition V18": 23,
    "Artist's Proof Edition V19": 22,
    "Artist's Proof Edition V20": 23,
    "Artist's Proof Edition V21": 22,
    "Artist's Proof Edition V22": 23,
    "First Edition V1": 21,
    "First Edition V2": 22,
    "First Edition V3": 21,
    "First Edition V4": 22,
    "First Edition V5": 21,
    "First Edition V6": 22,
    "First Edition V7": 21,
    "First Edition V8": 22,
    "First Edition V9": 21,
    "First Edition V10": 22,
    "First Edition V11": 21,
    "First Edition V12": 22,
    "First Edition V13": 21,
    "First Edition V14": 22,
    "First Edition V15": 21,
    "First Edition V16": 22,
    "First Edition V17": 21,
    "First Edition V18": 22,
    "First Edition V19": 21,
    "First Edition V20": 22,
    "First Edition V21": 21,
    "First Edition V22": 22
  },
  {
    "File Name": "eonRift_i1-p24.png",
    "Artist's Proof Edition V1": 23,
    "Artist's Proof Edition V2": 24,
    "Artist's Proof Edition V3": 23,
    "Artist's Proof Edition V4": 24,
    "Artist's Proof Edition V5": 23,
    "Artist's Proof Edition V6": 24,
    "Artist's Proof Edition V7": 23,
    "Artist's Proof Edition V8": 24,
    "Artist's Proof Edition V9": 23,
    "Artist's Proof Edition V10": 24,
    "Artist's Proof Edition V11": 23,
    "Artist's Proof Edition V12": 24,
    "Artist's Proof Edition V13": 23,
    "Artist's Proof Edition V14": 24,
    "Artist's Proof Edition V15": 23,
    "Artist's Proof Edition V16": 24,
    "Artist's Proof Edition V17": 23,
    "Artist's Proof Edition V18": 24,
    "Artist's Proof Edition V19": 23,
    "Artist's Proof Edition V20": 24,
    "Artist's Proof Edition V21": 23,
    "Artist's Proof Edition V22": 24,
    "First Edition V1": 22,
    "First Edition V2": 23,
    "First Edition V3": 22,
    "First Edition V4": 23,
    "First Edition V5": 22,
    "First Edition V6": 23,
    "First Edition V7": 22,
    "First Edition V8": 23,
    "First Edition V9": 22,
    "First Edition V10": 23,
    "First Edition V11": 22,
    "First Edition V12": 23,
    "First Edition V13": 22,
    "First Edition V14": 23,
    "First Edition V15": 22,
    "First Edition V16": 23,
    "First Edition V17": 22,
    "First Edition V18": 23,
    "First Edition V19": 22,
    "First Edition V20": 23,
    "First Edition V21": 22,
    "First Edition V22": 23
  },
  {
    "File Name": "eonRift_i1-p25.png",
    "Artist's Proof Edition V1": 24,
    "Artist's Proof Edition V2": 25,
    "Artist's Proof Edition V3": 24,
    "Artist's Proof Edition V4": 25,
    "Artist's Proof Edition V5": 24,
    "Artist's Proof Edition V6": 25,
    "Artist's Proof Edition V7": 24,
    "Artist's Proof Edition V8": 25,
    "Artist's Proof Edition V9": 24,
    "Artist's Proof Edition V10": 25,
    "Artist's Proof Edition V11": 24,
    "Artist's Proof Edition V12": 25,
    "Artist's Proof Edition V13": 24,
    "Artist's Proof Edition V14": 25,
    "Artist's Proof Edition V15": 24,
    "Artist's Proof Edition V16": 25,
    "Artist's Proof Edition V17": 24,
    "Artist's Proof Edition V18": 25,
    "Artist's Proof Edition V19": 24,
    "Artist's Proof Edition V20": 25,
    "Artist's Proof Edition V21": 24,
    "Artist's Proof Edition V22": 25,
    "First Edition V1": 23,
    "First Edition V2": 24,
    "First Edition V3": 23,
    "First Edition V4": 24,
    "First Edition V5": 23,
    "First Edition V6": 24,
    "First Edition V7": 23,
    "First Edition V8": 24,
    "First Edition V9": 23,
    "First Edition V10": 24,
    "First Edition V11": 23,
    "First Edition V12": 24,
    "First Edition V13": 23,
    "First Edition V14": 24,
    "First Edition V15": 23,
    "First Edition V16": 24,
    "First Edition V17": 23,
    "First Edition V18": 24,
    "First Edition V19": 23,
    "First Edition V20": 24,
    "First Edition V21": 23,
    "First Edition V22": 24
  },
  {
    "File Name": "eonRift_i1-p26.png",
    "Artist's Proof Edition V1": 25,
    "Artist's Proof Edition V2": 26,
    "Artist's Proof Edition V3": 25,
    "Artist's Proof Edition V4": 26,
    "Artist's Proof Edition V5": 25,
    "Artist's Proof Edition V6": 26,
    "Artist's Proof Edition V7": 25,
    "Artist's Proof Edition V8": 26,
    "Artist's Proof Edition V9": 25,
    "Artist's Proof Edition V10": 26,
    "Artist's Proof Edition V11": 25,
    "Artist's Proof Edition V12": 26,
    "Artist's Proof Edition V13": 25,
    "Artist's Proof Edition V14": 26,
    "Artist's Proof Edition V15": 25,
    "Artist's Proof Edition V16": 26,
    "Artist's Proof Edition V17": 25,
    "Artist's Proof Edition V18": 26,
    "Artist's Proof Edition V19": 25,
    "Artist's Proof Edition V20": 26,
    "Artist's Proof Edition V21": 25,
    "Artist's Proof Edition V22": 26,
    "First Edition V1": 24,
    "First Edition V2": 25,
    "First Edition V3": 24,
    "First Edition V4": 25,
    "First Edition V5": 24,
    "First Edition V6": 25,
    "First Edition V7": 24,
    "First Edition V8": 25,
    "First Edition V9": 24,
    "First Edition V10": 25,
    "First Edition V11": 24,
    "First Edition V12": 25,
    "First Edition V13": 24,
    "First Edition V14": 25,
    "First Edition V15": 24,
    "First Edition V16": 25,
    "First Edition V17": 24,
    "First Edition V18": 25,
    "First Edition V19": 24,
    "First Edition V20": 25,
    "First Edition V21": 24,
    "First Edition V22": 25
  },
  {
    "File Name": "eonRift_i1-p27.png",
    "Artist's Proof Edition V1": 26,
    "Artist's Proof Edition V2": 27,
    "Artist's Proof Edition V3": 26,
    "Artist's Proof Edition V4": 27,
    "Artist's Proof Edition V5": 26,
    "Artist's Proof Edition V6": 27,
    "Artist's Proof Edition V7": 26,
    "Artist's Proof Edition V8": 27,
    "Artist's Proof Edition V9": 26,
    "Artist's Proof Edition V10": 27,
    "Artist's Proof Edition V11": 26,
    "Artist's Proof Edition V12": 27,
    "Artist's Proof Edition V13": 26,
    "Artist's Proof Edition V14": 27,
    "Artist's Proof Edition V15": 26,
    "Artist's Proof Edition V16": 27,
    "Artist's Proof Edition V17": 26,
    "Artist's Proof Edition V18": 27,
    "Artist's Proof Edition V19": 26,
    "Artist's Proof Edition V20": 27,
    "Artist's Proof Edition V21": 26,
    "Artist's Proof Edition V22": 27,
    "First Edition V1": 25,
    "First Edition V2": 26,
    "First Edition V3": 25,
    "First Edition V4": 26,
    "First Edition V5": 25,
    "First Edition V6": 26,
    "First Edition V7": 25,
    "First Edition V8": 26,
    "First Edition V9": 25,
    "First Edition V10": 26,
    "First Edition V11": 25,
    "First Edition V12": 26,
    "First Edition V13": 25,
    "First Edition V14": 26,
    "First Edition V15": 25,
    "First Edition V16": 26,
    "First Edition V17": 25,
    "First Edition V18": 26,
    "First Edition V19": 25,
    "First Edition V20": 26,
    "First Edition V21": 25,
    "First Edition V22": 26
  },
  {
    "File Name": "eonRift_i1-p28.png",
    "Artist's Proof Edition V1": 27,
    "Artist's Proof Edition V2": 28,
    "Artist's Proof Edition V3": 27,
    "Artist's Proof Edition V4": 28,
    "Artist's Proof Edition V5": 27,
    "Artist's Proof Edition V6": 28,
    "Artist's Proof Edition V7": 27,
    "Artist's Proof Edition V8": 28,
    "Artist's Proof Edition V9": 27,
    "Artist's Proof Edition V10": 28,
    "Artist's Proof Edition V11": 27,
    "Artist's Proof Edition V12": 28,
    "Artist's Proof Edition V13": 27,
    "Artist's Proof Edition V14": 28,
    "Artist's Proof Edition V15": 27,
    "Artist's Proof Edition V16": 28,
    "Artist's Proof Edition V17": 27,
    "Artist's Proof Edition V18": 28,
    "Artist's Proof Edition V19": 27,
    "Artist's Proof Edition V20": 28,
    "Artist's Proof Edition V21": 27,
    "Artist's Proof Edition V22": 28,
    "First Edition V1": 26,
    "First Edition V2": 27,
    "First Edition V3": 26,
    "First Edition V4": 27,
    "First Edition V5": 26,
    "First Edition V6": 27,
    "First Edition V7": 26,
    "First Edition V8": 27,
    "First Edition V9": 26,
    "First Edition V10": 27,
    "First Edition V11": 26,
    "First Edition V12": 27,
    "First Edition V13": 26,
    "First Edition V14": 27,
    "First Edition V15": 26,
    "First Edition V16": 27,
    "First Edition V17": 26,
    "First Edition V18": 27,
    "First Edition V19": 26,
    "First Edition V20": 27,
    "First Edition V21": 26,
    "First Edition V22": 27
  },
  {
    "File Name": "eonRift_i1-p29.png",
    "Artist's Proof Edition V1": 28,
    "Artist's Proof Edition V2": 29,
    "Artist's Proof Edition V3": 28,
    "Artist's Proof Edition V4": 29,
    "Artist's Proof Edition V5": 28,
    "Artist's Proof Edition V6": 29,
    "Artist's Proof Edition V7": 28,
    "Artist's Proof Edition V8": 29,
    "Artist's Proof Edition V9": 28,
    "Artist's Proof Edition V10": 29,
    "Artist's Proof Edition V11": 28,
    "Artist's Proof Edition V12": 29,
    "Artist's Proof Edition V13": 28,
    "Artist's Proof Edition V14": 29,
    "Artist's Proof Edition V15": 28,
    "Artist's Proof Edition V16": 29,
    "Artist's Proof Edition V17": 28,
    "Artist's Proof Edition V18": 29,
    "Artist's Proof Edition V19": 28,
    "Artist's Proof Edition V20": 29,
    "Artist's Proof Edition V21": 28,
    "Artist's Proof Edition V22": 29,
    "First Edition V1": 27,
    "First Edition V2": 28,
    "First Edition V3": 27,
    "First Edition V4": 28,
    "First Edition V5": 27,
    "First Edition V6": 28,
    "First Edition V7": 27,
    "First Edition V8": 28,
    "First Edition V9": 27,
    "First Edition V10": 28,
    "First Edition V11": 27,
    "First Edition V12": 28,
    "First Edition V13": 27,
    "First Edition V14": 28,
    "First Edition V15": 27,
    "First Edition V16": 28,
    "First Edition V17": 27,
    "First Edition V18": 28,
    "First Edition V19": 27,
    "First Edition V20": 28,
    "First Edition V21": 27,
    "First Edition V22": 28
  },
  {
    "File Name": "eonRift_i1-p30.png",
    "Artist's Proof Edition V1": 29,
    "Artist's Proof Edition V2": 30,
    "Artist's Proof Edition V3": 29,
    "Artist's Proof Edition V4": 30,
    "Artist's Proof Edition V5": 29,
    "Artist's Proof Edition V6": 30,
    "Artist's Proof Edition V7": 29,
    "Artist's Proof Edition V8": 30,
    "Artist's Proof Edition V9": 29,
    "Artist's Proof Edition V10": 30,
    "Artist's Proof Edition V11": 29,
    "Artist's Proof Edition V12": 30,
    "Artist's Proof Edition V13": 29,
    "Artist's Proof Edition V14": 30,
    "Artist's Proof Edition V15": 29,
    "Artist's Proof Edition V16": 30,
    "Artist's Proof Edition V17": 29,
    "Artist's Proof Edition V18": 30,
    "Artist's Proof Edition V19": 29,
    "Artist's Proof Edition V20": 30,
    "Artist's Proof Edition V21": 29,
    "Artist's Proof Edition V22": 30,
    "First Edition V1": 28,
    "First Edition V2": 29,
    "First Edition V3": 28,
    "First Edition V4": 29,
    "First Edition V5": 28,
    "First Edition V6": 29,
    "First Edition V7": 28,
    "First Edition V8": 29,
    "First Edition V9": 28,
    "First Edition V10": 29,
    "First Edition V11": 28,
    "First Edition V12": 29,
    "First Edition V13": 28,
    "First Edition V14": 29,
    "First Edition V15": 28,
    "First Edition V16": 29,
    "First Edition V17": 28,
    "First Edition V18": 29,
    "First Edition V19": 28,
    "First Edition V20": 29,
    "First Edition V21": 28,
    "First Edition V22": 29
  },
  {
    "File Name": "eonRift_i1-p31.png",
    "Artist's Proof Edition V1": 30,
    "Artist's Proof Edition V2": 31,
    "Artist's Proof Edition V3": 30,
    "Artist's Proof Edition V4": 31,
    "Artist's Proof Edition V5": 30,
    "Artist's Proof Edition V6": 31,
    "Artist's Proof Edition V7": 30,
    "Artist's Proof Edition V8": 31,
    "Artist's Proof Edition V9": 30,
    "Artist's Proof Edition V10": 31,
    "Artist's Proof Edition V11": 30,
    "Artist's Proof Edition V12": 31,
    "Artist's Proof Edition V13": 30,
    "Artist's Proof Edition V14": 31,
    "Artist's Proof Edition V15": 30,
    "Artist's Proof Edition V16": 31,
    "Artist's Proof Edition V17": 30,
    "Artist's Proof Edition V18": 31,
    "Artist's Proof Edition V19": 30,
    "Artist's Proof Edition V20": 31,
    "Artist's Proof Edition V21": 30,
    "Artist's Proof Edition V22": 31,
    "First Edition V1": 29,
    "First Edition V2": 30,
    "First Edition V3": 29,
    "First Edition V4": 30,
    "First Edition V5": 29,
    "First Edition V6": 30,
    "First Edition V7": 29,
    "First Edition V8": 30,
    "First Edition V9": 29,
    "First Edition V10": 30,
    "First Edition V11": 29,
    "First Edition V12": 30,
    "First Edition V13": 29,
    "First Edition V14": 30,
    "First Edition V15": 29,
    "First Edition V16": 30,
    "First Edition V17": 29,
    "First Edition V18": 30,
    "First Edition V19": 29,
    "First Edition V20": 30,
    "First Edition V21": 29,
    "First Edition V22": 30
  },
  {
    "File Name": "eonRift_i1-p32.png",
    "Artist's Proof Edition V1": 31,
    "Artist's Proof Edition V2": 32,
    "Artist's Proof Edition V3": 31,
    "Artist's Proof Edition V4": 32,
    "Artist's Proof Edition V5": 31,
    "Artist's Proof Edition V6": 32,
    "Artist's Proof Edition V7": 31,
    "Artist's Proof Edition V8": 32,
    "Artist's Proof Edition V9": 31,
    "Artist's Proof Edition V10": 32,
    "Artist's Proof Edition V11": 31,
    "Artist's Proof Edition V12": 32,
    "Artist's Proof Edition V13": 31,
    "Artist's Proof Edition V14": 32,
    "Artist's Proof Edition V15": 31,
    "Artist's Proof Edition V16": 32,
    "Artist's Proof Edition V17": 31,
    "Artist's Proof Edition V18": 32,
    "Artist's Proof Edition V19": 31,
    "Artist's Proof Edition V20": 32,
    "Artist's Proof Edition V21": 31,
    "Artist's Proof Edition V22": 32,
    "First Edition V1": 30,
    "First Edition V2": 31,
    "First Edition V3": 30,
    "First Edition V4": 31,
    "First Edition V5": 30,
    "First Edition V6": 31,
    "First Edition V7": 30,
    "First Edition V8": 31,
    "First Edition V9": 30,
    "First Edition V10": 31,
    "First Edition V11": 30,
    "First Edition V12": 31,
    "First Edition V13": 30,
    "First Edition V14": 31,
    "First Edition V15": 30,
    "First Edition V16": 31,
    "First Edition V17": 30,
    "First Edition V18": 31,
    "First Edition V19": 30,
    "First Edition V20": 31,
    "First Edition V21": 30,
    "First Edition V22": 31
  },
  {
    "File Name": "eonRift_i1-p33.png",
    "Artist's Proof Edition V1": 32,
    "Artist's Proof Edition V2": 33,
    "Artist's Proof Edition V3": 32,
    "Artist's Proof Edition V4": 33,
    "Artist's Proof Edition V5": 32,
    "Artist's Proof Edition V6": 33,
    "Artist's Proof Edition V7": 32,
    "Artist's Proof Edition V8": 33,
    "Artist's Proof Edition V9": 32,
    "Artist's Proof Edition V10": 33,
    "Artist's Proof Edition V11": 32,
    "Artist's Proof Edition V12": 33,
    "Artist's Proof Edition V13": 32,
    "Artist's Proof Edition V14": 33,
    "Artist's Proof Edition V15": 32,
    "Artist's Proof Edition V16": 33,
    "Artist's Proof Edition V17": 32,
    "Artist's Proof Edition V18": 33,
    "Artist's Proof Edition V19": 32,
    "Artist's Proof Edition V20": 33,
    "Artist's Proof Edition V21": 32,
    "Artist's Proof Edition V22": 33,
    "First Edition V1": 31,
    "First Edition V2": 32,
    "First Edition V3": 31,
    "First Edition V4": 32,
    "First Edition V5": 31,
    "First Edition V6": 32,
    "First Edition V7": 31,
    "First Edition V8": 32,
    "First Edition V9": 31,
    "First Edition V10": 32,
    "First Edition V11": 31,
    "First Edition V12": 32,
    "First Edition V13": 31,
    "First Edition V14": 32,
    "First Edition V15": 31,
    "First Edition V16": 32,
    "First Edition V17": 31,
    "First Edition V18": 32,
    "First Edition V19": 31,
    "First Edition V20": 32,
    "First Edition V21": 31,
    "First Edition V22": 32
  },
  {
    "File Name": "eonRift_i1-p34.png",
    "Artist's Proof Edition V1": 33,
    "Artist's Proof Edition V2": 34,
    "Artist's Proof Edition V3": 33,
    "Artist's Proof Edition V4": 34,
    "Artist's Proof Edition V5": 33,
    "Artist's Proof Edition V6": 34,
    "Artist's Proof Edition V7": 33,
    "Artist's Proof Edition V8": 34,
    "Artist's Proof Edition V9": 33,
    "Artist's Proof Edition V10": 34,
    "Artist's Proof Edition V11": 33,
    "Artist's Proof Edition V12": 34,
    "Artist's Proof Edition V13": 33,
    "Artist's Proof Edition V14": 34,
    "Artist's Proof Edition V15": 33,
    "Artist's Proof Edition V16": 34,
    "Artist's Proof Edition V17": 33,
    "Artist's Proof Edition V18": 34,
    "Artist's Proof Edition V19": 33,
    "Artist's Proof Edition V20": 34,
    "Artist's Proof Edition V21": 33,
    "Artist's Proof Edition V22": 34,
    "First Edition V1": 32,
    "First Edition V2": 33,
    "First Edition V3": 32,
    "First Edition V4": 33,
    "First Edition V5": 32,
    "First Edition V6": 33,
    "First Edition V7": 32,
    "First Edition V8": 33,
    "First Edition V9": 32,
    "First Edition V10": 33,
    "First Edition V11": 32,
    "First Edition V12": 33,
    "First Edition V13": 32,
    "First Edition V14": 33,
    "First Edition V15": 32,
    "First Edition V16": 33,
    "First Edition V17": 32,
    "First Edition V18": 33,
    "First Edition V19": 32,
    "First Edition V20": 33,
    "First Edition V21": 32,
    "First Edition V22": 33
  },
  {
    "File Name": "eonRift_i1-p35.png",
    "Artist's Proof Edition V1": 34,
    "Artist's Proof Edition V2": 35,
    "Artist's Proof Edition V3": 34,
    "Artist's Proof Edition V4": 35,
    "Artist's Proof Edition V5": 34,
    "Artist's Proof Edition V6": 35,
    "Artist's Proof Edition V7": 34,
    "Artist's Proof Edition V8": 35,
    "Artist's Proof Edition V9": 34,
    "Artist's Proof Edition V10": 35,
    "Artist's Proof Edition V11": 34,
    "Artist's Proof Edition V12": 35,
    "Artist's Proof Edition V13": 34,
    "Artist's Proof Edition V14": 35,
    "Artist's Proof Edition V15": 34,
    "Artist's Proof Edition V16": 35,
    "Artist's Proof Edition V17": 34,
    "Artist's Proof Edition V18": 35,
    "Artist's Proof Edition V19": 34,
    "Artist's Proof Edition V20": 35,
    "Artist's Proof Edition V21": 34,
    "Artist's Proof Edition V22": 35,
    "First Edition V1": 33,
    "First Edition V2": 34,
    "First Edition V3": 33,
    "First Edition V4": 34,
    "First Edition V5": 33,
    "First Edition V6": 34,
    "First Edition V7": 33,
    "First Edition V8": 34,
    "First Edition V9": 33,
    "First Edition V10": 34,
    "First Edition V11": 33,
    "First Edition V12": 34,
    "First Edition V13": 33,
    "First Edition V14": 34,
    "First Edition V15": 33,
    "First Edition V16": 34,
    "First Edition V17": 33,
    "First Edition V18": 34,
    "First Edition V19": 33,
    "First Edition V20": 34,
    "First Edition V21": 33,
    "First Edition V22": 34
  },
  {
    "File Name": "eonRift_i1-p36.png",
    "Artist's Proof Edition V1": 35,
    "Artist's Proof Edition V2": 36,
    "Artist's Proof Edition V3": 35,
    "Artist's Proof Edition V4": 36,
    "Artist's Proof Edition V5": 35,
    "Artist's Proof Edition V6": 36,
    "Artist's Proof Edition V7": 35,
    "Artist's Proof Edition V8": 36,
    "Artist's Proof Edition V9": 35,
    "Artist's Proof Edition V10": 36,
    "Artist's Proof Edition V11": 35,
    "Artist's Proof Edition V12": 36,
    "Artist's Proof Edition V13": 35,
    "Artist's Proof Edition V14": 36,
    "Artist's Proof Edition V15": 35,
    "Artist's Proof Edition V16": 36,
    "Artist's Proof Edition V17": 35,
    "Artist's Proof Edition V18": 36,
    "Artist's Proof Edition V19": 35,
    "Artist's Proof Edition V20": 36,
    "Artist's Proof Edition V21": 35,
    "Artist's Proof Edition V22": 36,
    "First Edition V1": 34,
    "First Edition V2": 35,
    "First Edition V3": 34,
    "First Edition V4": 35,
    "First Edition V5": 34,
    "First Edition V6": 35,
    "First Edition V7": 34,
    "First Edition V8": 35,
    "First Edition V9": 34,
    "First Edition V10": 35,
    "First Edition V11": 34,
    "First Edition V12": 35,
    "First Edition V13": 34,
    "First Edition V14": 35,
    "First Edition V15": 34,
    "First Edition V16": 35,
    "First Edition V17": 34,
    "First Edition V18": 35,
    "First Edition V19": 34,
    "First Edition V20": 35,
    "First Edition V21": 34,
    "First Edition V22": 35
  },
  {
    "File Name": "eonRift_i1-p37.png",
    "Artist's Proof Edition V1": 36,
    "Artist's Proof Edition V2": 37,
    "Artist's Proof Edition V3": 36,
    "Artist's Proof Edition V4": 37,
    "Artist's Proof Edition V5": 36,
    "Artist's Proof Edition V6": 37,
    "Artist's Proof Edition V7": 36,
    "Artist's Proof Edition V8": 37,
    "Artist's Proof Edition V9": 36,
    "Artist's Proof Edition V10": 37,
    "Artist's Proof Edition V11": 36,
    "Artist's Proof Edition V12": 37,
    "Artist's Proof Edition V13": 36,
    "Artist's Proof Edition V14": 37,
    "Artist's Proof Edition V15": 36,
    "Artist's Proof Edition V16": 37,
    "Artist's Proof Edition V17": 36,
    "Artist's Proof Edition V18": 37,
    "Artist's Proof Edition V19": 36,
    "Artist's Proof Edition V20": 37,
    "Artist's Proof Edition V21": 36,
    "Artist's Proof Edition V22": 37,
    "First Edition V1": 35,
    "First Edition V2": 36,
    "First Edition V3": 35,
    "First Edition V4": 36,
    "First Edition V5": 35,
    "First Edition V6": 36,
    "First Edition V7": 35,
    "First Edition V8": 36,
    "First Edition V9": 35,
    "First Edition V10": 36,
    "First Edition V11": 35,
    "First Edition V12": 36,
    "First Edition V13": 35,
    "First Edition V14": 36,
    "First Edition V15": 35,
    "First Edition V16": 36,
    "First Edition V17": 35,
    "First Edition V18": 36,
    "First Edition V19": 35,
    "First Edition V20": 36,
    "First Edition V21": 35,
    "First Edition V22": 36
  },
  {
    "File Name": "eonRift_i1-p38.png",
    "Artist's Proof Edition V1": 37,
    "Artist's Proof Edition V2": 38,
    "Artist's Proof Edition V3": 37,
    "Artist's Proof Edition V4": 38,
    "Artist's Proof Edition V5": 37,
    "Artist's Proof Edition V6": 38,
    "Artist's Proof Edition V7": 37,
    "Artist's Proof Edition V8": 38,
    "Artist's Proof Edition V9": 37,
    "Artist's Proof Edition V10": 38,
    "Artist's Proof Edition V11": 37,
    "Artist's Proof Edition V12": 38,
    "Artist's Proof Edition V13": 37,
    "Artist's Proof Edition V14": 38,
    "Artist's Proof Edition V15": 37,
    "Artist's Proof Edition V16": 38,
    "Artist's Proof Edition V17": 37,
    "Artist's Proof Edition V18": 38,
    "Artist's Proof Edition V19": 37,
    "Artist's Proof Edition V20": 38,
    "Artist's Proof Edition V21": 37,
    "Artist's Proof Edition V22": 38,
    "First Edition V1": 36,
    "First Edition V2": 37,
    "First Edition V3": 36,
    "First Edition V4": 37,
    "First Edition V5": 36,
    "First Edition V6": 37,
    "First Edition V7": 36,
    "First Edition V8": 37,
    "First Edition V9": 36,
    "First Edition V10": 37,
    "First Edition V11": 36,
    "First Edition V12": 37,
    "First Edition V13": 36,
    "First Edition V14": 37,
    "First Edition V15": 36,
    "First Edition V16": 37,
    "First Edition V17": 36,
    "First Edition V18": 37,
    "First Edition V19": 36,
    "First Edition V20": 37,
    "First Edition V21": 36,
    "First Edition V22": 37
  },
  {
    "File Name": "eonRift_i1-p39.png",
    "Artist's Proof Edition V1": 38,
    "Artist's Proof Edition V2": 39,
    "Artist's Proof Edition V3": 38,
    "Artist's Proof Edition V4": 39,
    "Artist's Proof Edition V5": 38,
    "Artist's Proof Edition V6": 39,
    "Artist's Proof Edition V7": 38,
    "Artist's Proof Edition V8": 39,
    "Artist's Proof Edition V9": 38,
    "Artist's Proof Edition V10": 39,
    "Artist's Proof Edition V11": 38,
    "Artist's Proof Edition V12": 39,
    "Artist's Proof Edition V13": 38,
    "Artist's Proof Edition V14": 39,
    "Artist's Proof Edition V15": 38,
    "Artist's Proof Edition V16": 39,
    "Artist's Proof Edition V17": 38,
    "Artist's Proof Edition V18": 39,
    "Artist's Proof Edition V19": 38,
    "Artist's Proof Edition V20": 39,
    "Artist's Proof Edition V21": 38,
    "Artist's Proof Edition V22": 39,
    "First Edition V1": 37,
    "First Edition V2": 38,
    "First Edition V3": 37,
    "First Edition V4": 38,
    "First Edition V5": 37,
    "First Edition V6": 38,
    "First Edition V7": 37,
    "First Edition V8": 38,
    "First Edition V9": 37,
    "First Edition V10": 38,
    "First Edition V11": 37,
    "First Edition V12": 38,
    "First Edition V13": 37,
    "First Edition V14": 38,
    "First Edition V15": 37,
    "First Edition V16": 38,
    "First Edition V17": 37,
    "First Edition V18": 38,
    "First Edition V19": 37,
    "First Edition V20": 38,
    "First Edition V21": 37,
    "First Edition V22": 38
  },
  {
    "File Name": "eonRift_i1-p40.png",
    "Artist's Proof Edition V1": 39,
    "Artist's Proof Edition V2": 40,
    "Artist's Proof Edition V3": 39,
    "Artist's Proof Edition V4": 40,
    "Artist's Proof Edition V5": 39,
    "Artist's Proof Edition V6": 40,
    "Artist's Proof Edition V7": 39,
    "Artist's Proof Edition V8": 40,
    "Artist's Proof Edition V9": 39,
    "Artist's Proof Edition V10": 40,
    "Artist's Proof Edition V11": 39,
    "Artist's Proof Edition V12": 40,
    "Artist's Proof Edition V13": 39,
    "Artist's Proof Edition V14": 40,
    "Artist's Proof Edition V15": 39,
    "Artist's Proof Edition V16": 40,
    "Artist's Proof Edition V17": 39,
    "Artist's Proof Edition V18": 40,
    "Artist's Proof Edition V19": 39,
    "Artist's Proof Edition V20": 40,
    "Artist's Proof Edition V21": 39,
    "Artist's Proof Edition V22": 40,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p41.png",
    "Artist's Proof Edition V1": 40,
    "Artist's Proof Edition V2": 41,
    "Artist's Proof Edition V3": 40,
    "Artist's Proof Edition V4": 41,
    "Artist's Proof Edition V5": 40,
    "Artist's Proof Edition V6": 41,
    "Artist's Proof Edition V7": 40,
    "Artist's Proof Edition V8": 41,
    "Artist's Proof Edition V9": 40,
    "Artist's Proof Edition V10": 41,
    "Artist's Proof Edition V11": 40,
    "Artist's Proof Edition V12": 41,
    "Artist's Proof Edition V13": 40,
    "Artist's Proof Edition V14": 41,
    "Artist's Proof Edition V15": 40,
    "Artist's Proof Edition V16": 41,
    "Artist's Proof Edition V17": 40,
    "Artist's Proof Edition V18": 41,
    "Artist's Proof Edition V19": 40,
    "Artist's Proof Edition V20": 41,
    "Artist's Proof Edition V21": 40,
    "Artist's Proof Edition V22": 41,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p42.png",
    "Artist's Proof Edition V1": 41,
    "Artist's Proof Edition V2": 42,
    "Artist's Proof Edition V3": 41,
    "Artist's Proof Edition V4": 42,
    "Artist's Proof Edition V5": 41,
    "Artist's Proof Edition V6": 42,
    "Artist's Proof Edition V7": 41,
    "Artist's Proof Edition V8": 42,
    "Artist's Proof Edition V9": 41,
    "Artist's Proof Edition V10": 42,
    "Artist's Proof Edition V11": 41,
    "Artist's Proof Edition V12": 42,
    "Artist's Proof Edition V13": 41,
    "Artist's Proof Edition V14": 42,
    "Artist's Proof Edition V15": 41,
    "Artist's Proof Edition V16": 42,
    "Artist's Proof Edition V17": 41,
    "Artist's Proof Edition V18": 42,
    "Artist's Proof Edition V19": 41,
    "Artist's Proof Edition V20": 42,
    "Artist's Proof Edition V21": 41,
    "Artist's Proof Edition V22": 42,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p43.png",
    "Artist's Proof Edition V1": 42,
    "Artist's Proof Edition V2": 43,
    "Artist's Proof Edition V3": 42,
    "Artist's Proof Edition V4": 43,
    "Artist's Proof Edition V5": 42,
    "Artist's Proof Edition V6": 43,
    "Artist's Proof Edition V7": 42,
    "Artist's Proof Edition V8": 43,
    "Artist's Proof Edition V9": 42,
    "Artist's Proof Edition V10": 43,
    "Artist's Proof Edition V11": 42,
    "Artist's Proof Edition V12": 43,
    "Artist's Proof Edition V13": 42,
    "Artist's Proof Edition V14": 43,
    "Artist's Proof Edition V15": 42,
    "Artist's Proof Edition V16": 43,
    "Artist's Proof Edition V17": 42,
    "Artist's Proof Edition V18": 43,
    "Artist's Proof Edition V19": 42,
    "Artist's Proof Edition V20": 43,
    "Artist's Proof Edition V21": 42,
    "Artist's Proof Edition V22": 43,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p44.png",
    "Artist's Proof Edition V1": 43,
    "Artist's Proof Edition V2": 44,
    "Artist's Proof Edition V3": 43,
    "Artist's Proof Edition V4": 44,
    "Artist's Proof Edition V5": 43,
    "Artist's Proof Edition V6": 44,
    "Artist's Proof Edition V7": 43,
    "Artist's Proof Edition V8": 44,
    "Artist's Proof Edition V9": 43,
    "Artist's Proof Edition V10": 44,
    "Artist's Proof Edition V11": 43,
    "Artist's Proof Edition V12": 44,
    "Artist's Proof Edition V13": 43,
    "Artist's Proof Edition V14": 44,
    "Artist's Proof Edition V15": 43,
    "Artist's Proof Edition V16": 44,
    "Artist's Proof Edition V17": 43,
    "Artist's Proof Edition V18": 44,
    "Artist's Proof Edition V19": 43,
    "Artist's Proof Edition V20": 44,
    "Artist's Proof Edition V21": 43,
    "Artist's Proof Edition V22": 44,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p45.png",
    "Artist's Proof Edition V1": 44,
    "Artist's Proof Edition V2": 45,
    "Artist's Proof Edition V3": 44,
    "Artist's Proof Edition V4": 45,
    "Artist's Proof Edition V5": 44,
    "Artist's Proof Edition V6": 45,
    "Artist's Proof Edition V7": 44,
    "Artist's Proof Edition V8": 45,
    "Artist's Proof Edition V9": 44,
    "Artist's Proof Edition V10": 45,
    "Artist's Proof Edition V11": 44,
    "Artist's Proof Edition V12": 45,
    "Artist's Proof Edition V13": 44,
    "Artist's Proof Edition V14": 45,
    "Artist's Proof Edition V15": 44,
    "Artist's Proof Edition V16": 45,
    "Artist's Proof Edition V17": 44,
    "Artist's Proof Edition V18": 45,
    "Artist's Proof Edition V19": 44,
    "Artist's Proof Edition V20": 45,
    "Artist's Proof Edition V21": 44,
    "Artist's Proof Edition V22": 45,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p46.png",
    "Artist's Proof Edition V1": 45,
    "Artist's Proof Edition V2": 46,
    "Artist's Proof Edition V3": 45,
    "Artist's Proof Edition V4": 46,
    "Artist's Proof Edition V5": 45,
    "Artist's Proof Edition V6": 46,
    "Artist's Proof Edition V7": 45,
    "Artist's Proof Edition V8": 46,
    "Artist's Proof Edition V9": 45,
    "Artist's Proof Edition V10": 46,
    "Artist's Proof Edition V11": 45,
    "Artist's Proof Edition V12": 46,
    "Artist's Proof Edition V13": 45,
    "Artist's Proof Edition V14": 46,
    "Artist's Proof Edition V15": 45,
    "Artist's Proof Edition V16": 46,
    "Artist's Proof Edition V17": 45,
    "Artist's Proof Edition V18": 46,
    "Artist's Proof Edition V19": 45,
    "Artist's Proof Edition V20": 46,
    "Artist's Proof Edition V21": 45,
    "Artist's Proof Edition V22": 46,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p47.png",
    "Artist's Proof Edition V1": 46,
    "Artist's Proof Edition V2": 47,
    "Artist's Proof Edition V3": 46,
    "Artist's Proof Edition V4": 47,
    "Artist's Proof Edition V5": 46,
    "Artist's Proof Edition V6": 47,
    "Artist's Proof Edition V7": 46,
    "Artist's Proof Edition V8": 47,
    "Artist's Proof Edition V9": 46,
    "Artist's Proof Edition V10": 47,
    "Artist's Proof Edition V11": 46,
    "Artist's Proof Edition V12": 47,
    "Artist's Proof Edition V13": 46,
    "Artist's Proof Edition V14": 47,
    "Artist's Proof Edition V15": 46,
    "Artist's Proof Edition V16": 47,
    "Artist's Proof Edition V17": 46,
    "Artist's Proof Edition V18": 47,
    "Artist's Proof Edition V19": 46,
    "Artist's Proof Edition V20": 47,
    "Artist's Proof Edition V21": 46,
    "Artist's Proof Edition V22": 47,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p48.png",
    "Artist's Proof Edition V1": 47,
    "Artist's Proof Edition V2": 48,
    "Artist's Proof Edition V3": 47,
    "Artist's Proof Edition V4": 48,
    "Artist's Proof Edition V5": 47,
    "Artist's Proof Edition V6": 48,
    "Artist's Proof Edition V7": 47,
    "Artist's Proof Edition V8": 48,
    "Artist's Proof Edition V9": 47,
    "Artist's Proof Edition V10": 48,
    "Artist's Proof Edition V11": 47,
    "Artist's Proof Edition V12": 48,
    "Artist's Proof Edition V13": 47,
    "Artist's Proof Edition V14": 48,
    "Artist's Proof Edition V15": 47,
    "Artist's Proof Edition V16": 48,
    "Artist's Proof Edition V17": 47,
    "Artist's Proof Edition V18": 48,
    "Artist's Proof Edition V19": 47,
    "Artist's Proof Edition V20": 48,
    "Artist's Proof Edition V21": 47,
    "Artist's Proof Edition V22": 48,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p49.png",
    "Artist's Proof Edition V1": 48,
    "Artist's Proof Edition V2": 49,
    "Artist's Proof Edition V3": 48,
    "Artist's Proof Edition V4": 49,
    "Artist's Proof Edition V5": 48,
    "Artist's Proof Edition V6": 49,
    "Artist's Proof Edition V7": 48,
    "Artist's Proof Edition V8": 49,
    "Artist's Proof Edition V9": 48,
    "Artist's Proof Edition V10": 49,
    "Artist's Proof Edition V11": 48,
    "Artist's Proof Edition V12": 49,
    "Artist's Proof Edition V13": 48,
    "Artist's Proof Edition V14": 49,
    "Artist's Proof Edition V15": 48,
    "Artist's Proof Edition V16": 49,
    "Artist's Proof Edition V17": 48,
    "Artist's Proof Edition V18": 49,
    "Artist's Proof Edition V19": 48,
    "Artist's Proof Edition V20": 49,
    "Artist's Proof Edition V21": 48,
    "Artist's Proof Edition V22": 49,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p50.png",
    "Artist's Proof Edition V1": 49,
    "Artist's Proof Edition V2": 50,
    "Artist's Proof Edition V3": 49,
    "Artist's Proof Edition V4": 50,
    "Artist's Proof Edition V5": 49,
    "Artist's Proof Edition V6": 50,
    "Artist's Proof Edition V7": 49,
    "Artist's Proof Edition V8": 50,
    "Artist's Proof Edition V9": 49,
    "Artist's Proof Edition V10": 50,
    "Artist's Proof Edition V11": 49,
    "Artist's Proof Edition V12": 50,
    "Artist's Proof Edition V13": 49,
    "Artist's Proof Edition V14": 50,
    "Artist's Proof Edition V15": 49,
    "Artist's Proof Edition V16": 50,
    "Artist's Proof Edition V17": 49,
    "Artist's Proof Edition V18": 50,
    "Artist's Proof Edition V19": 49,
    "Artist's Proof Edition V20": 50,
    "Artist's Proof Edition V21": 49,
    "Artist's Proof Edition V22": 50,
    "First Edition V1": 38,
    "First Edition V2": 39,
    "First Edition V3": 38,
    "First Edition V4": 39,
    "First Edition V5": 38,
    "First Edition V6": 39,
    "First Edition V7": 38,
    "First Edition V8": 39,
    "First Edition V9": 38,
    "First Edition V10": 39,
    "First Edition V11": 38,
    "First Edition V12": 39,
    "First Edition V13": 38,
    "First Edition V14": 39,
    "First Edition V15": 38,
    "First Edition V16": 39,
    "First Edition V17": 38,
    "First Edition V18": 39,
    "First Edition V19": 38,
    "First Edition V20": 39,
    "First Edition V21": 38,
    "First Edition V22": 39
  },
  {
    "File Name": "eonRift_i1-p51.png",
    "Artist's Proof Edition V1": 50,
    "Artist's Proof Edition V2": 51,
    "Artist's Proof Edition V3": 50,
    "Artist's Proof Edition V4": 51,
    "Artist's Proof Edition V5": 50,
    "Artist's Proof Edition V6": 51,
    "Artist's Proof Edition V7": 50,
    "Artist's Proof Edition V8": 51,
    "Artist's Proof Edition V9": 50,
    "Artist's Proof Edition V10": 51,
    "Artist's Proof Edition V11": 50,
    "Artist's Proof Edition V12": 51,
    "Artist's Proof Edition V13": 50,
    "Artist's Proof Edition V14": 51,
    "Artist's Proof Edition V15": 50,
    "Artist's Proof Edition V16": 51,
    "Artist's Proof Edition V17": 50,
    "Artist's Proof Edition V18": 51,
    "Artist's Proof Edition V19": 50,
    "Artist's Proof Edition V20": 51,
    "Artist's Proof Edition V21": 50,
    "Artist's Proof Edition V22": 51,
    "First Edition V1": 39,
    "First Edition V2": 40,
    "First Edition V3": 39,
    "First Edition V4": 40,
    "First Edition V5": 39,
    "First Edition V6": 40,
    "First Edition V7": 39,
    "First Edition V8": 40,
    "First Edition V9": 39,
    "First Edition V10": 40,
    "First Edition V11": 39,
    "First Edition V12": 40,
    "First Edition V13": 39,
    "First Edition V14": 40,
    "First Edition V15": 39,
    "First Edition V16": 40,
    "First Edition V17": 39,
    "First Edition V18": 40,
    "First Edition V19": 39,
    "First Edition V20": 40,
    "First Edition V21": 39,
    "First Edition V22": 40
  },
  {
    "File Name": "eonRift_i1-p52.png",
    "Artist's Proof Edition V1": 51,
    "Artist's Proof Edition V2": 52,
    "Artist's Proof Edition V3": 51,
    "Artist's Proof Edition V4": 52,
    "Artist's Proof Edition V5": 51,
    "Artist's Proof Edition V6": 52,
    "Artist's Proof Edition V7": 51,
    "Artist's Proof Edition V8": 52,
    "Artist's Proof Edition V9": 51,
    "Artist's Proof Edition V10": 52,
    "Artist's Proof Edition V11": 51,
    "Artist's Proof Edition V12": 52,
    "Artist's Proof Edition V13": 51,
    "Artist's Proof Edition V14": 52,
    "Artist's Proof Edition V15": 51,
    "Artist's Proof Edition V16": 52,
    "Artist's Proof Edition V17": 51,
    "Artist's Proof Edition V18": 52,
    "Artist's Proof Edition V19": 51,
    "Artist's Proof Edition V20": 52,
    "Artist's Proof Edition V21": 51,
    "Artist's Proof Edition V22": 52,
    "First Edition V1": 40,
    "First Edition V2": 41,
    "First Edition V3": 40,
    "First Edition V4": 41,
    "First Edition V5": 40,
    "First Edition V6": 41,
    "First Edition V7": 40,
    "First Edition V8": 41,
    "First Edition V9": 40,
    "First Edition V10": 41,
    "First Edition V11": 40,
    "First Edition V12": 41,
    "First Edition V13": 40,
    "First Edition V14": 41,
    "First Edition V15": 40,
    "First Edition V16": 41,
    "First Edition V17": 40,
    "First Edition V18": 41,
    "First Edition V19": 40,
    "First Edition V20": 41,
    "First Edition V21": 40,
    "First Edition V22": 41
  },
  {
    "File Name": "eonRift_i1-p53.png",
    "Artist's Proof Edition V1": 52,
    "Artist's Proof Edition V2": 53,
    "Artist's Proof Edition V3": 52,
    "Artist's Proof Edition V4": 53,
    "Artist's Proof Edition V5": 52,
    "Artist's Proof Edition V6": 53,
    "Artist's Proof Edition V7": 52,
    "Artist's Proof Edition V8": 53,
    "Artist's Proof Edition V9": 52,
    "Artist's Proof Edition V10": 53,
    "Artist's Proof Edition V11": 52,
    "Artist's Proof Edition V12": 53,
    "Artist's Proof Edition V13": 52,
    "Artist's Proof Edition V14": 53,
    "Artist's Proof Edition V15": 52,
    "Artist's Proof Edition V16": 53,
    "Artist's Proof Edition V17": 52,
    "Artist's Proof Edition V18": 53,
    "Artist's Proof Edition V19": 52,
    "Artist's Proof Edition V20": 53,
    "Artist's Proof Edition V21": 52,
    "Artist's Proof Edition V22": 53,
    "First Edition V1": 41,
    "First Edition V2": 42,
    "First Edition V3": 41,
    "First Edition V4": 42,
    "First Edition V5": 41,
    "First Edition V6": 42,
    "First Edition V7": 41,
    "First Edition V8": 42,
    "First Edition V9": 41,
    "First Edition V10": 42,
    "First Edition V11": 41,
    "First Edition V12": 42,
    "First Edition V13": 41,
    "First Edition V14": 42,
    "First Edition V15": 41,
    "First Edition V16": 42,
    "First Edition V17": 41,
    "First Edition V18": 42,
    "First Edition V19": 41,
    "First Edition V20": 42,
    "First Edition V21": 41,
    "First Edition V22": 42
  },
  {
    "File Name": "eonRift_i1-p54.png",
    "Artist's Proof Edition V1": 53,
    "Artist's Proof Edition V2": 54,
    "Artist's Proof Edition V3": 53,
    "Artist's Proof Edition V4": 54,
    "Artist's Proof Edition V5": 53,
    "Artist's Proof Edition V6": 54,
    "Artist's Proof Edition V7": 53,
    "Artist's Proof Edition V8": 54,
    "Artist's Proof Edition V9": 53,
    "Artist's Proof Edition V10": 54,
    "Artist's Proof Edition V11": 53,
    "Artist's Proof Edition V12": 54,
    "Artist's Proof Edition V13": 53,
    "Artist's Proof Edition V14": 54,
    "Artist's Proof Edition V15": 53,
    "Artist's Proof Edition V16": 54,
    "Artist's Proof Edition V17": 53,
    "Artist's Proof Edition V18": 54,
    "Artist's Proof Edition V19": 53,
    "Artist's Proof Edition V20": 54,
    "Artist's Proof Edition V21": 53,
    "Artist's Proof Edition V22": 54,
    "First Edition V1": 42,
    "First Edition V2": 43,
    "First Edition V3": 42,
    "First Edition V4": 43,
    "First Edition V5": 42,
    "First Edition V6": 43,
    "First Edition V7": 42,
    "First Edition V8": 43,
    "First Edition V9": 42,
    "First Edition V10": 43,
    "First Edition V11": 42,
    "First Edition V12": 43,
    "First Edition V13": 42,
    "First Edition V14": 43,
    "First Edition V15": 42,
    "First Edition V16": 43,
    "First Edition V17": 42,
    "First Edition V18": 43,
    "First Edition V19": 42,
    "First Edition V20": 43,
    "First Edition V21": 42,
    "First Edition V22": 43
  },
  {
    "File Name": "eonRift_i1-p55.png",
    "Artist's Proof Edition V1": 54,
    "Artist's Proof Edition V2": 55,
    "Artist's Proof Edition V3": 54,
    "Artist's Proof Edition V4": 55,
    "Artist's Proof Edition V5": 54,
    "Artist's Proof Edition V6": 55,
    "Artist's Proof Edition V7": 54,
    "Artist's Proof Edition V8": 55,
    "Artist's Proof Edition V9": 54,
    "Artist's Proof Edition V10": 55,
    "Artist's Proof Edition V11": 54,
    "Artist's Proof Edition V12": 55,
    "Artist's Proof Edition V13": 54,
    "Artist's Proof Edition V14": 55,
    "Artist's Proof Edition V15": 54,
    "Artist's Proof Edition V16": 55,
    "Artist's Proof Edition V17": 54,
    "Artist's Proof Edition V18": 55,
    "Artist's Proof Edition V19": 54,
    "Artist's Proof Edition V20": 55,
    "Artist's Proof Edition V21": 54,
    "Artist's Proof Edition V22": 55,
    "First Edition V1": 43,
    "First Edition V2": 44,
    "First Edition V3": 43,
    "First Edition V4": 44,
    "First Edition V5": 43,
    "First Edition V6": 44,
    "First Edition V7": 43,
    "First Edition V8": 44,
    "First Edition V9": 43,
    "First Edition V10": 44,
    "First Edition V11": 43,
    "First Edition V12": 44,
    "First Edition V13": 43,
    "First Edition V14": 44,
    "First Edition V15": 43,
    "First Edition V16": 44,
    "First Edition V17": 43,
    "First Edition V18": 44,
    "First Edition V19": 43,
    "First Edition V20": 44,
    "First Edition V21": 43,
    "First Edition V22": 44
  },
  {
    "File Name": "eonRift_i1-p56.png",
    "Artist's Proof Edition V1": 55,
    "Artist's Proof Edition V2": 56,
    "Artist's Proof Edition V3": 55,
    "Artist's Proof Edition V4": 56,
    "Artist's Proof Edition V5": 55,
    "Artist's Proof Edition V6": 56,
    "Artist's Proof Edition V7": 55,
    "Artist's Proof Edition V8": 56,
    "Artist's Proof Edition V9": 55,
    "Artist's Proof Edition V10": 56,
    "Artist's Proof Edition V11": 55,
    "Artist's Proof Edition V12": 56,
    "Artist's Proof Edition V13": 55,
    "Artist's Proof Edition V14": 56,
    "Artist's Proof Edition V15": 55,
    "Artist's Proof Edition V16": 56,
    "Artist's Proof Edition V17": 55,
    "Artist's Proof Edition V18": 56,
    "Artist's Proof Edition V19": 55,
    "Artist's Proof Edition V20": 56,
    "Artist's Proof Edition V21": 55,
    "Artist's Proof Edition V22": 56,
    "First Edition V1": 44,
    "First Edition V2": 45,
    "First Edition V3": 44,
    "First Edition V4": 45,
    "First Edition V5": 44,
    "First Edition V6": 45,
    "First Edition V7": 44,
    "First Edition V8": 45,
    "First Edition V9": 44,
    "First Edition V10": 45,
    "First Edition V11": 44,
    "First Edition V12": 45,
    "First Edition V13": 44,
    "First Edition V14": 45,
    "First Edition V15": 44,
    "First Edition V16": 45,
    "First Edition V17": 44,
    "First Edition V18": 45,
    "First Edition V19": 44,
    "First Edition V20": 45,
    "First Edition V21": 44,
    "First Edition V22": 45
  },
  {
    "File Name": "eonRift_i1-p57.png",
    "Artist's Proof Edition V1": 56,
    "Artist's Proof Edition V2": 57,
    "Artist's Proof Edition V3": 56,
    "Artist's Proof Edition V4": 57,
    "Artist's Proof Edition V5": 56,
    "Artist's Proof Edition V6": 57,
    "Artist's Proof Edition V7": 56,
    "Artist's Proof Edition V8": 57,
    "Artist's Proof Edition V9": 56,
    "Artist's Proof Edition V10": 57,
    "Artist's Proof Edition V11": 56,
    "Artist's Proof Edition V12": 57,
    "Artist's Proof Edition V13": 56,
    "Artist's Proof Edition V14": 57,
    "Artist's Proof Edition V15": 56,
    "Artist's Proof Edition V16": 57,
    "Artist's Proof Edition V17": 56,
    "Artist's Proof Edition V18": 57,
    "Artist's Proof Edition V19": 56,
    "Artist's Proof Edition V20": 57,
    "Artist's Proof Edition V21": 56,
    "Artist's Proof Edition V22": 57,
    "First Edition V1": 45,
    "First Edition V2": 46,
    "First Edition V3": 45,
    "First Edition V4": 46,
    "First Edition V5": 45,
    "First Edition V6": 46,
    "First Edition V7": 45,
    "First Edition V8": 46,
    "First Edition V9": 45,
    "First Edition V10": 46,
    "First Edition V11": 45,
    "First Edition V12": 46,
    "First Edition V13": 45,
    "First Edition V14": 46,
    "First Edition V15": 45,
    "First Edition V16": 46,
    "First Edition V17": 45,
    "First Edition V18": 46,
    "First Edition V19": 45,
    "First Edition V20": 46,
    "First Edition V21": 45,
    "First Edition V22": 46
  },
  {
    "File Name": "eonRift_i1-p58.png",
    "Artist's Proof Edition V1": 57,
    "Artist's Proof Edition V2": 58,
    "Artist's Proof Edition V3": 57,
    "Artist's Proof Edition V4": 58,
    "Artist's Proof Edition V5": 57,
    "Artist's Proof Edition V6": 58,
    "Artist's Proof Edition V7": 57,
    "Artist's Proof Edition V8": 58,
    "Artist's Proof Edition V9": 57,
    "Artist's Proof Edition V10": 58,
    "Artist's Proof Edition V11": 57,
    "Artist's Proof Edition V12": 58,
    "Artist's Proof Edition V13": 57,
    "Artist's Proof Edition V14": 58,
    "Artist's Proof Edition V15": 57,
    "Artist's Proof Edition V16": 58,
    "Artist's Proof Edition V17": 57,
    "Artist's Proof Edition V18": 58,
    "Artist's Proof Edition V19": 57,
    "Artist's Proof Edition V20": 58,
    "Artist's Proof Edition V21": 57,
    "Artist's Proof Edition V22": 58,
    "First Edition V1": 46,
    "First Edition V2": 47,
    "First Edition V3": 46,
    "First Edition V4": 47,
    "First Edition V5": 46,
    "First Edition V6": 47,
    "First Edition V7": 46,
    "First Edition V8": 47,
    "First Edition V9": 46,
    "First Edition V10": 47,
    "First Edition V11": 46,
    "First Edition V12": 47,
    "First Edition V13": 46,
    "First Edition V14": 47,
    "First Edition V15": 46,
    "First Edition V16": 47,
    "First Edition V17": 46,
    "First Edition V18": 47,
    "First Edition V19": 46,
    "First Edition V20": 47,
    "First Edition V21": 46,
    "First Edition V22": 47
  },
  {
    "File Name": "eonRift_i1-p59.png",
    "Artist's Proof Edition V1": 58,
    "Artist's Proof Edition V2": 59,
    "Artist's Proof Edition V3": 58,
    "Artist's Proof Edition V4": 59,
    "Artist's Proof Edition V5": 58,
    "Artist's Proof Edition V6": 59,
    "Artist's Proof Edition V7": 58,
    "Artist's Proof Edition V8": 59,
    "Artist's Proof Edition V9": 58,
    "Artist's Proof Edition V10": 59,
    "Artist's Proof Edition V11": 58,
    "Artist's Proof Edition V12": 59,
    "Artist's Proof Edition V13": 58,
    "Artist's Proof Edition V14": 59,
    "Artist's Proof Edition V15": 58,
    "Artist's Proof Edition V16": 59,
    "Artist's Proof Edition V17": 58,
    "Artist's Proof Edition V18": 59,
    "Artist's Proof Edition V19": 58,
    "Artist's Proof Edition V20": 59,
    "Artist's Proof Edition V21": 58,
    "Artist's Proof Edition V22": 59,
    "First Edition V1": 47,
    "First Edition V2": 48,
    "First Edition V3": 47,
    "First Edition V4": 48,
    "First Edition V5": 47,
    "First Edition V6": 48,
    "First Edition V7": 47,
    "First Edition V8": 48,
    "First Edition V9": 47,
    "First Edition V10": 48,
    "First Edition V11": 47,
    "First Edition V12": 48,
    "First Edition V13": 47,
    "First Edition V14": 48,
    "First Edition V15": 47,
    "First Edition V16": 48,
    "First Edition V17": 47,
    "First Edition V18": 48,
    "First Edition V19": 47,
    "First Edition V20": 48,
    "First Edition V21": 47,
    "First Edition V22": 48
  },
  {
    "File Name": "eonRift_i1-p60.png",
    "Artist's Proof Edition V1": 59,
    "Artist's Proof Edition V2": 60,
    "Artist's Proof Edition V3": 59,
    "Artist's Proof Edition V4": 60,
    "Artist's Proof Edition V5": 59,
    "Artist's Proof Edition V6": 60,
    "Artist's Proof Edition V7": 59,
    "Artist's Proof Edition V8": 60,
    "Artist's Proof Edition V9": 59,
    "Artist's Proof Edition V10": 60,
    "Artist's Proof Edition V11": 59,
    "Artist's Proof Edition V12": 60,
    "Artist's Proof Edition V13": 59,
    "Artist's Proof Edition V14": 60,
    "Artist's Proof Edition V15": 59,
    "Artist's Proof Edition V16": 60,
    "Artist's Proof Edition V17": 59,
    "Artist's Proof Edition V18": 60,
    "Artist's Proof Edition V19": 59,
    "Artist's Proof Edition V20": 60,
    "Artist's Proof Edition V21": 59,
    "Artist's Proof Edition V22": 60,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p61.png",
    "Artist's Proof Edition V1": 60,
    "Artist's Proof Edition V2": 61,
    "Artist's Proof Edition V3": 60,
    "Artist's Proof Edition V4": 61,
    "Artist's Proof Edition V5": 60,
    "Artist's Proof Edition V6": 61,
    "Artist's Proof Edition V7": 60,
    "Artist's Proof Edition V8": 61,
    "Artist's Proof Edition V9": 60,
    "Artist's Proof Edition V10": 61,
    "Artist's Proof Edition V11": 60,
    "Artist's Proof Edition V12": 61,
    "Artist's Proof Edition V13": 60,
    "Artist's Proof Edition V14": 61,
    "Artist's Proof Edition V15": 60,
    "Artist's Proof Edition V16": 61,
    "Artist's Proof Edition V17": 60,
    "Artist's Proof Edition V18": 61,
    "Artist's Proof Edition V19": 60,
    "Artist's Proof Edition V20": 61,
    "Artist's Proof Edition V21": 60,
    "Artist's Proof Edition V22": 61,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p62.png",
    "Artist's Proof Edition V1": 61,
    "Artist's Proof Edition V2": 62,
    "Artist's Proof Edition V3": 61,
    "Artist's Proof Edition V4": 62,
    "Artist's Proof Edition V5": 61,
    "Artist's Proof Edition V6": 62,
    "Artist's Proof Edition V7": 61,
    "Artist's Proof Edition V8": 62,
    "Artist's Proof Edition V9": 61,
    "Artist's Proof Edition V10": 62,
    "Artist's Proof Edition V11": 61,
    "Artist's Proof Edition V12": 62,
    "Artist's Proof Edition V13": 61,
    "Artist's Proof Edition V14": 62,
    "Artist's Proof Edition V15": 61,
    "Artist's Proof Edition V16": 62,
    "Artist's Proof Edition V17": 61,
    "Artist's Proof Edition V18": 62,
    "Artist's Proof Edition V19": 61,
    "Artist's Proof Edition V20": 62,
    "Artist's Proof Edition V21": 61,
    "Artist's Proof Edition V22": 62,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p63.png",
    "Artist's Proof Edition V1": 62,
    "Artist's Proof Edition V2": 63,
    "Artist's Proof Edition V3": 62,
    "Artist's Proof Edition V4": 63,
    "Artist's Proof Edition V5": 62,
    "Artist's Proof Edition V6": 63,
    "Artist's Proof Edition V7": 62,
    "Artist's Proof Edition V8": 63,
    "Artist's Proof Edition V9": 62,
    "Artist's Proof Edition V10": 63,
    "Artist's Proof Edition V11": 62,
    "Artist's Proof Edition V12": 63,
    "Artist's Proof Edition V13": 62,
    "Artist's Proof Edition V14": 63,
    "Artist's Proof Edition V15": 62,
    "Artist's Proof Edition V16": 63,
    "Artist's Proof Edition V17": 62,
    "Artist's Proof Edition V18": 63,
    "Artist's Proof Edition V19": 62,
    "Artist's Proof Edition V20": 63,
    "Artist's Proof Edition V21": 62,
    "Artist's Proof Edition V22": 63,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p64.png",
    "Artist's Proof Edition V1": 63,
    "Artist's Proof Edition V2": 64,
    "Artist's Proof Edition V3": 63,
    "Artist's Proof Edition V4": 64,
    "Artist's Proof Edition V5": 63,
    "Artist's Proof Edition V6": 64,
    "Artist's Proof Edition V7": 63,
    "Artist's Proof Edition V8": 64,
    "Artist's Proof Edition V9": 63,
    "Artist's Proof Edition V10": 64,
    "Artist's Proof Edition V11": 63,
    "Artist's Proof Edition V12": 64,
    "Artist's Proof Edition V13": 63,
    "Artist's Proof Edition V14": 64,
    "Artist's Proof Edition V15": 63,
    "Artist's Proof Edition V16": 64,
    "Artist's Proof Edition V17": 63,
    "Artist's Proof Edition V18": 64,
    "Artist's Proof Edition V19": 63,
    "Artist's Proof Edition V20": 64,
    "Artist's Proof Edition V21": 63,
    "Artist's Proof Edition V22": 64,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p65.png",
    "Artist's Proof Edition V1": 64,
    "Artist's Proof Edition V2": 65,
    "Artist's Proof Edition V3": 64,
    "Artist's Proof Edition V4": 65,
    "Artist's Proof Edition V5": 64,
    "Artist's Proof Edition V6": 65,
    "Artist's Proof Edition V7": 64,
    "Artist's Proof Edition V8": 65,
    "Artist's Proof Edition V9": 64,
    "Artist's Proof Edition V10": 65,
    "Artist's Proof Edition V11": 64,
    "Artist's Proof Edition V12": 65,
    "Artist's Proof Edition V13": 64,
    "Artist's Proof Edition V14": 65,
    "Artist's Proof Edition V15": 64,
    "Artist's Proof Edition V16": 65,
    "Artist's Proof Edition V17": 64,
    "Artist's Proof Edition V18": 65,
    "Artist's Proof Edition V19": 64,
    "Artist's Proof Edition V20": 65,
    "Artist's Proof Edition V21": 64,
    "Artist's Proof Edition V22": 65,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p66.png",
    "Artist's Proof Edition V1": 65,
    "Artist's Proof Edition V2": 66,
    "Artist's Proof Edition V3": 65,
    "Artist's Proof Edition V4": 66,
    "Artist's Proof Edition V5": 65,
    "Artist's Proof Edition V6": 66,
    "Artist's Proof Edition V7": 65,
    "Artist's Proof Edition V8": 66,
    "Artist's Proof Edition V9": 65,
    "Artist's Proof Edition V10": 66,
    "Artist's Proof Edition V11": 65,
    "Artist's Proof Edition V12": 66,
    "Artist's Proof Edition V13": 65,
    "Artist's Proof Edition V14": 66,
    "Artist's Proof Edition V15": 65,
    "Artist's Proof Edition V16": 66,
    "Artist's Proof Edition V17": 65,
    "Artist's Proof Edition V18": 66,
    "Artist's Proof Edition V19": 65,
    "Artist's Proof Edition V20": 66,
    "Artist's Proof Edition V21": 65,
    "Artist's Proof Edition V22": 66,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p67.png",
    "Artist's Proof Edition V1": 66,
    "Artist's Proof Edition V2": 67,
    "Artist's Proof Edition V3": 66,
    "Artist's Proof Edition V4": 67,
    "Artist's Proof Edition V5": 66,
    "Artist's Proof Edition V6": 67,
    "Artist's Proof Edition V7": 66,
    "Artist's Proof Edition V8": 67,
    "Artist's Proof Edition V9": 66,
    "Artist's Proof Edition V10": 67,
    "Artist's Proof Edition V11": 66,
    "Artist's Proof Edition V12": 67,
    "Artist's Proof Edition V13": 66,
    "Artist's Proof Edition V14": 67,
    "Artist's Proof Edition V15": 66,
    "Artist's Proof Edition V16": 67,
    "Artist's Proof Edition V17": 66,
    "Artist's Proof Edition V18": 67,
    "Artist's Proof Edition V19": 66,
    "Artist's Proof Edition V20": 67,
    "Artist's Proof Edition V21": 66,
    "Artist's Proof Edition V22": 67,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p68.png",
    "Artist's Proof Edition V1": 67,
    "Artist's Proof Edition V2": 68,
    "Artist's Proof Edition V3": 67,
    "Artist's Proof Edition V4": 68,
    "Artist's Proof Edition V5": 67,
    "Artist's Proof Edition V6": 68,
    "Artist's Proof Edition V7": 67,
    "Artist's Proof Edition V8": 68,
    "Artist's Proof Edition V9": 67,
    "Artist's Proof Edition V10": 68,
    "Artist's Proof Edition V11": 67,
    "Artist's Proof Edition V12": 68,
    "Artist's Proof Edition V13": 67,
    "Artist's Proof Edition V14": 68,
    "Artist's Proof Edition V15": 67,
    "Artist's Proof Edition V16": 68,
    "Artist's Proof Edition V17": 67,
    "Artist's Proof Edition V18": 68,
    "Artist's Proof Edition V19": 67,
    "Artist's Proof Edition V20": 68,
    "Artist's Proof Edition V21": 67,
    "Artist's Proof Edition V22": 68,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p69.png",
    "Artist's Proof Edition V1": 68,
    "Artist's Proof Edition V2": 69,
    "Artist's Proof Edition V3": 68,
    "Artist's Proof Edition V4": 69,
    "Artist's Proof Edition V5": 68,
    "Artist's Proof Edition V6": 69,
    "Artist's Proof Edition V7": 68,
    "Artist's Proof Edition V8": 69,
    "Artist's Proof Edition V9": 68,
    "Artist's Proof Edition V10": 69,
    "Artist's Proof Edition V11": 68,
    "Artist's Proof Edition V12": 69,
    "Artist's Proof Edition V13": 68,
    "Artist's Proof Edition V14": 69,
    "Artist's Proof Edition V15": 68,
    "Artist's Proof Edition V16": 69,
    "Artist's Proof Edition V17": 68,
    "Artist's Proof Edition V18": 69,
    "Artist's Proof Edition V19": 68,
    "Artist's Proof Edition V20": 69,
    "Artist's Proof Edition V21": 68,
    "Artist's Proof Edition V22": 69,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p70.png",
    "Artist's Proof Edition V1": 69,
    "Artist's Proof Edition V2": 70,
    "Artist's Proof Edition V3": 69,
    "Artist's Proof Edition V4": 70,
    "Artist's Proof Edition V5": 69,
    "Artist's Proof Edition V6": 70,
    "Artist's Proof Edition V7": 69,
    "Artist's Proof Edition V8": 70,
    "Artist's Proof Edition V9": 69,
    "Artist's Proof Edition V10": 70,
    "Artist's Proof Edition V11": 69,
    "Artist's Proof Edition V12": 70,
    "Artist's Proof Edition V13": 69,
    "Artist's Proof Edition V14": 70,
    "Artist's Proof Edition V15": 69,
    "Artist's Proof Edition V16": 70,
    "Artist's Proof Edition V17": 69,
    "Artist's Proof Edition V18": 70,
    "Artist's Proof Edition V19": 69,
    "Artist's Proof Edition V20": 70,
    "Artist's Proof Edition V21": 69,
    "Artist's Proof Edition V22": 70,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p71.png",
    "Artist's Proof Edition V1": 70,
    "Artist's Proof Edition V2": 71,
    "Artist's Proof Edition V3": 70,
    "Artist's Proof Edition V4": 71,
    "Artist's Proof Edition V5": 70,
    "Artist's Proof Edition V6": 71,
    "Artist's Proof Edition V7": 70,
    "Artist's Proof Edition V8": 71,
    "Artist's Proof Edition V9": 70,
    "Artist's Proof Edition V10": 71,
    "Artist's Proof Edition V11": 70,
    "Artist's Proof Edition V12": 71,
    "Artist's Proof Edition V13": 70,
    "Artist's Proof Edition V14": 71,
    "Artist's Proof Edition V15": 70,
    "Artist's Proof Edition V16": 71,
    "Artist's Proof Edition V17": 70,
    "Artist's Proof Edition V18": 71,
    "Artist's Proof Edition V19": 70,
    "Artist's Proof Edition V20": 71,
    "Artist's Proof Edition V21": 70,
    "Artist's Proof Edition V22": 71,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p72.png",
    "Artist's Proof Edition V1": 71,
    "Artist's Proof Edition V2": 72,
    "Artist's Proof Edition V3": 71,
    "Artist's Proof Edition V4": 72,
    "Artist's Proof Edition V5": 71,
    "Artist's Proof Edition V6": 72,
    "Artist's Proof Edition V7": 71,
    "Artist's Proof Edition V8": 72,
    "Artist's Proof Edition V9": 71,
    "Artist's Proof Edition V10": 72,
    "Artist's Proof Edition V11": 71,
    "Artist's Proof Edition V12": 72,
    "Artist's Proof Edition V13": 71,
    "Artist's Proof Edition V14": 72,
    "Artist's Proof Edition V15": 71,
    "Artist's Proof Edition V16": 72,
    "Artist's Proof Edition V17": 71,
    "Artist's Proof Edition V18": 72,
    "Artist's Proof Edition V19": 71,
    "Artist's Proof Edition V20": 72,
    "Artist's Proof Edition V21": 71,
    "Artist's Proof Edition V22": 72,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p73.png",
    "Artist's Proof Edition V1": 72,
    "Artist's Proof Edition V2": 73,
    "Artist's Proof Edition V3": 72,
    "Artist's Proof Edition V4": 73,
    "Artist's Proof Edition V5": 72,
    "Artist's Proof Edition V6": 73,
    "Artist's Proof Edition V7": 72,
    "Artist's Proof Edition V8": 73,
    "Artist's Proof Edition V9": 72,
    "Artist's Proof Edition V10": 73,
    "Artist's Proof Edition V11": 72,
    "Artist's Proof Edition V12": 73,
    "Artist's Proof Edition V13": 72,
    "Artist's Proof Edition V14": 73,
    "Artist's Proof Edition V15": 72,
    "Artist's Proof Edition V16": 73,
    "Artist's Proof Edition V17": 72,
    "Artist's Proof Edition V18": 73,
    "Artist's Proof Edition V19": 72,
    "Artist's Proof Edition V20": 73,
    "Artist's Proof Edition V21": 72,
    "Artist's Proof Edition V22": 73,
    "First Edition V1": 48,
    "First Edition V2": 49,
    "First Edition V3": 48,
    "First Edition V4": 49,
    "First Edition V5": 48,
    "First Edition V6": 49,
    "First Edition V7": 48,
    "First Edition V8": 49,
    "First Edition V9": 48,
    "First Edition V10": 49,
    "First Edition V11": 48,
    "First Edition V12": 49,
    "First Edition V13": 48,
    "First Edition V14": 49,
    "First Edition V15": 48,
    "First Edition V16": 49,
    "First Edition V17": 48,
    "First Edition V18": 49,
    "First Edition V19": 48,
    "First Edition V20": 49,
    "First Edition V21": 48,
    "First Edition V22": 49
  },
  {
    "File Name": "eonRift_i1-p74.png",
    "Artist's Proof Edition V1": 73,
    "Artist's Proof Edition V2": 74,
    "Artist's Proof Edition V3": 73,
    "Artist's Proof Edition V4": 74,
    "Artist's Proof Edition V5": 73,
    "Artist's Proof Edition V6": 74,
    "Artist's Proof Edition V7": 73,
    "Artist's Proof Edition V8": 74,
    "Artist's Proof Edition V9": 73,
    "Artist's Proof Edition V10": 74,
    "Artist's Proof Edition V11": 73,
    "Artist's Proof Edition V12": 74,
    "Artist's Proof Edition V13": 73,
    "Artist's Proof Edition V14": 74,
    "Artist's Proof Edition V15": 73,
    "Artist's Proof Edition V16": 74,
    "Artist's Proof Edition V17": 73,
    "Artist's Proof Edition V18": 74,
    "Artist's Proof Edition V19": 73,
    "Artist's Proof Edition V20": 74,
    "Artist's Proof Edition V21": 73,
    "Artist's Proof Edition V22": 74,
    "First Edition V1": 49,
    "First Edition V2": 50,
    "First Edition V3": 49,
    "First Edition V4": 50,
    "First Edition V5": 49,
    "First Edition V6": 50,
    "First Edition V7": 49,
    "First Edition V8": 50,
    "First Edition V9": 49,
    "First Edition V10": 50,
    "First Edition V11": 49,
    "First Edition V12": 50,
    "First Edition V13": 49,
    "First Edition V14": 50,
    "First Edition V15": 49,
    "First Edition V16": 50,
    "First Edition V17": 49,
    "First Edition V18": 50,
    "First Edition V19": 49,
    "First Edition V20": 50,
    "First Edition V21": 49,
    "First Edition V22": 50
  },
  {
    "File Name": "eonRift_i1-p75.png",
    "Artist's Proof Edition V1": 74,
    "Artist's Proof Edition V2": 75,
    "Artist's Proof Edition V3": 74,
    "Artist's Proof Edition V4": 75,
    "Artist's Proof Edition V5": 74,
    "Artist's Proof Edition V6": 75,
    "Artist's Proof Edition V7": 74,
    "Artist's Proof Edition V8": 75,
    "Artist's Proof Edition V9": 74,
    "Artist's Proof Edition V10": 75,
    "Artist's Proof Edition V11": 74,
    "Artist's Proof Edition V12": 75,
    "Artist's Proof Edition V13": 74,
    "Artist's Proof Edition V14": 75,
    "Artist's Proof Edition V15": 74,
    "Artist's Proof Edition V16": 75,
    "Artist's Proof Edition V17": 74,
    "Artist's Proof Edition V18": 75,
    "Artist's Proof Edition V19": 74,
    "Artist's Proof Edition V20": 75,
    "Artist's Proof Edition V21": 74,
    "Artist's Proof Edition V22": 75,
    "First Edition V1": 50,
    "First Edition V2": 51,
    "First Edition V3": 50,
    "First Edition V4": 51,
    "First Edition V5": 50,
    "First Edition V6": 51,
    "First Edition V7": 50,
    "First Edition V8": 51,
    "First Edition V9": 50,
    "First Edition V10": 51,
    "First Edition V11": 50,
    "First Edition V12": 51,
    "First Edition V13": 50,
    "First Edition V14": 51,
    "First Edition V15": 50,
    "First Edition V16": 51,
    "First Edition V17": 50,
    "First Edition V18": 51,
    "First Edition V19": 50,
    "First Edition V20": 51,
    "First Edition V21": 50,
    "First Edition V22": 51
  },
  {
    "File Name": "eonRift_i1-p76.png",
    "Artist's Proof Edition V1": 75,
    "Artist's Proof Edition V2": 76,
    "Artist's Proof Edition V3": 75,
    "Artist's Proof Edition V4": 76,
    "Artist's Proof Edition V5": 75,
    "Artist's Proof Edition V6": 76,
    "Artist's Proof Edition V7": 75,
    "Artist's Proof Edition V8": 76,
    "Artist's Proof Edition V9": 75,
    "Artist's Proof Edition V10": 76,
    "Artist's Proof Edition V11": 75,
    "Artist's Proof Edition V12": 76,
    "Artist's Proof Edition V13": 75,
    "Artist's Proof Edition V14": 76,
    "Artist's Proof Edition V15": 75,
    "Artist's Proof Edition V16": 76,
    "Artist's Proof Edition V17": 75,
    "Artist's Proof Edition V18": 76,
    "Artist's Proof Edition V19": 75,
    "Artist's Proof Edition V20": 76,
    "Artist's Proof Edition V21": 75,
    "Artist's Proof Edition V22": 76,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p77.png",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": 77,
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": 77,
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": 77,
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": 77,
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": 77,
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": 77,
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": 77,
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": 77,
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": 77,
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": 77,
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": 77,
    "First Edition V1": "-",
    "First Edition V2": 52,
    "First Edition V3": "-",
    "First Edition V4": 52,
    "First Edition V5": "-",
    "First Edition V6": 52,
    "First Edition V7": "-",
    "First Edition V8": 52,
    "First Edition V9": "-",
    "First Edition V10": 52,
    "First Edition V11": "-",
    "First Edition V12": 52,
    "First Edition V13": "-",
    "First Edition V14": 52,
    "First Edition V15": "-",
    "First Edition V16": 52,
    "First Edition V17": "-",
    "First Edition V18": 52,
    "First Edition V19": "-",
    "First Edition V20": 52,
    "First Edition V21": "-",
    "First Edition V22": 52
  },
  {
    "File Name": "eonRift_i1-p78.png",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 76,
    "Artist's Proof Edition V22": 78,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 51,
    "First Edition V22": 53
  },
  {
    "File Name": "eonRift_i1-p79.png",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": "-",
    "Artist's Proof Edition V22": 79,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": "-",
    "First Edition V22": "-"
  },
  {
    "File Name": "eonRift_i1-p80.png",
    "Artist's Proof Edition V1": "-",
    "Artist's Proof Edition V2": "-",
    "Artist's Proof Edition V3": "-",
    "Artist's Proof Edition V4": "-",
    "Artist's Proof Edition V5": "-",
    "Artist's Proof Edition V6": "-",
    "Artist's Proof Edition V7": "-",
    "Artist's Proof Edition V8": "-",
    "Artist's Proof Edition V9": "-",
    "Artist's Proof Edition V10": "-",
    "Artist's Proof Edition V11": "-",
    "Artist's Proof Edition V12": "-",
    "Artist's Proof Edition V13": "-",
    "Artist's Proof Edition V14": "-",
    "Artist's Proof Edition V15": "-",
    "Artist's Proof Edition V16": "-",
    "Artist's Proof Edition V17": "-",
    "Artist's Proof Edition V18": "-",
    "Artist's Proof Edition V19": "-",
    "Artist's Proof Edition V20": "-",
    "Artist's Proof Edition V21": 77,
    "Artist's Proof Edition V22": 80,
    "First Edition V1": "-",
    "First Edition V2": "-",
    "First Edition V3": "-",
    "First Edition V4": "-",
    "First Edition V5": "-",
    "First Edition V6": "-",
    "First Edition V7": "-",
    "First Edition V8": "-",
    "First Edition V9": "-",
    "First Edition V10": "-",
    "First Edition V11": "-",
    "First Edition V12": "-",
    "First Edition V13": "-",
    "First Edition V14": "-",
    "First Edition V15": "-",
    "First Edition V16": "-",
    "First Edition V17": "-",
    "First Edition V18": "-",
    "First Edition V19": "-",
    "First Edition V20": "-",
    "First Edition V21": 52,
    "First Edition V22": 54
  },
  {
    "File Name": "eonRift_i1-p81.png",
    "Artist's Proof Edition V1": 76,
    "Artist's Proof Edition V2": 78,
    "Artist's Proof Edition V3": 76,
    "Artist's Proof Edition V4": 78,
    "Artist's Proof Edition V5": 76,
    "Artist's Proof Edition V6": 78,
    "Artist's Proof Edition V7": 76,
    "Artist's Proof Edition V8": 78,
    "Artist's Proof Edition V9": 76,
    "Artist's Proof Edition V10": 78,
    "Artist's Proof Edition V11": 76,
    "Artist's Proof Edition V12": 78,
    "Artist's Proof Edition V13": 76,
    "Artist's Proof Edition V14": 78,
    "Artist's Proof Edition V15": 76,
    "Artist's Proof Edition V16": 78,
    "Artist's Proof Edition V17": 76,
    "Artist's Proof Edition V18": 78,
    "Artist's Proof Edition V19": 76,
    "Artist's Proof Edition V20": 78,
    "Artist's Proof Edition V21": 78,
    "Artist's Proof Edition V22": 81,
    "First Edition V1": 51,
    "First Edition V2": 53,
    "First Edition V3": 51,
    "First Edition V4": 53,
    "First Edition V5": 51,
    "First Edition V6": 53,
    "First Edition V7": 51,
    "First Edition V8": 53,
    "First Edition V9": 51,
    "First Edition V10": 53,
    "First Edition V11": 51,
    "First Edition V12": 53,
    "First Edition V13": 51,
    "First Edition V14": 53,
    "First Edition V15": 51,
    "First Edition V16": 53,
    "First Edition V17": 51,
    "First Edition V18": 53,
    "First Edition V19": 51,
    "First Edition V20": 53,
    "First Edition V21": 53,
    "First Edition V22": 55
  },
  {
    "File Name": "eonRift_i1-p82.png",
    "Artist's Proof Edition V1": 77,
    "Artist's Proof Edition V2": 79,
    "Artist's Proof Edition V3": 77,
    "Artist's Proof Edition V4": 79,
    "Artist's Proof Edition V5": 77,
    "Artist's Proof Edition V6": 79,
    "Artist's Proof Edition V7": 77,
    "Artist's Proof Edition V8": 79,
    "Artist's Proof Edition V9": 77,
    "Artist's Proof Edition V10": 79,
    "Artist's Proof Edition V11": 77,
    "Artist's Proof Edition V12": 79,
    "Artist's Proof Edition V13": 77,
    "Artist's Proof Edition V14": 79,
    "Artist's Proof Edition V15": 77,
    "Artist's Proof Edition V16": 79,
    "Artist's Proof Edition V17": 77,
    "Artist's Proof Edition V18": 79,
    "Artist's Proof Edition V19": 77,
    "Artist's Proof Edition V20": 79,
    "Artist's Proof Edition V21": 79,
    "Artist's Proof Edition V22": 82,
    "First Edition V1": 52,
    "First Edition V2": 54,
    "First Edition V3": 52,
    "First Edition V4": 54,
    "First Edition V5": 52,
    "First Edition V6": 54,
    "First Edition V7": 52,
    "First Edition V8": 54,
    "First Edition V9": 52,
    "First Edition V10": 54,
    "First Edition V11": 52,
    "First Edition V12": 54,
    "First Edition V13": 52,
    "First Edition V14": 54,
    "First Edition V15": 52,
    "First Edition V16": 54,
    "First Edition V17": 52,
    "First Edition V18": 54,
    "First Edition V19": 52,
    "First Edition V20": 54,
    "First Edition V21": 54,
    "First Edition V22": 56
  }
]
