import React, { useRef, useState, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';

import Page from './Page';
import PrevButtonClick from './PrevButtonClick';
import NextButtonClick from './NextButtonClick';

import { COVER_MEDIA_FILES, PREVIEW_COMIC } from 'constants';

/*
eonRift_i1-p01.png (cover)
eonRift_i1-p04.png (credits)
eonRift_i1-p05.png (logo)
eonRift_i1-p06.png (cover overview)
eonRift_i1-p07.mp4 (story page 1)
eonRift_i1-p08.png (story page 2)
eonRift_i1-p09.png (story page 3)
eonRift_i1-p10.png (story page 4)
eonRift_i1-p11_public.png (story page 5 - see attached)
*/

const DesktopPreview = () => {
  const book = useRef();
  const [page, setPage] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isBookClose = page === 0;

  const isBookFinished = page === PREVIEW_COMIC.length - 2;

  const onPage = e => {
    setPage(e.data);
  };

  const nextButtonClick = () => {
    book.current.pageFlip().flipNext();
  };

  const prevButtonClick = () => {
    book.current.pageFlip().flipPrev();
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={`hidden justify-center items-center h-screen relative min-[630px]:flex`}
    >
      <div className="hidden xs:block absolute top-1/2 -translate-x-1/2 left-8 min-[1180px]:left-14 min-[2500px]:left-40">
        {!isBookClose && <PrevButtonClick buttonClick={prevButtonClick} />}
      </div>

      <div className="hidden xs:block  absolute top-1/2 -translate-x-1/2 right-[-25px] min-[1180px]:right-0  min-[2500px]:right-32">
        {!isBookFinished && <NextButtonClick buttonClick={nextButtonClick} />}
      </div>

      <HTMLFlipBook
        width={550}
        height={windowSize.width >= 640 ? 733 : 850}
        size="stretch"
        minWidth={315}
        maxWidth={875}
        minHeight={400}
        maxHeight={windowSize.height}
        showCover={true}
        mobileScrollSupport={true}
        onFlip={onPage}
        onChangeOrientation={book.onChangeOrientation}
        onChangeState={book.onChangeState}
        maxShadowOpacity={0}
        className="flex justify-center items-center"
        ref={book}
      >
        {PREVIEW_COMIC.map(({ id, image, desc }, index) => {
          return (
            <Page
              key={id}
              number={id}
              desc={desc}
              currentPage={index}
              width={windowSize.width}
              isCover={
                image === 'null' ||
                COVER_MEDIA_FILES.includes(image.split('/').pop())
              }
              img={
                <>
                  {image.includes('.mp4') ? (
                    <div
                      className={` flex 
                        ${
                          index > 0 && index % 2 === 0
                            ? ' justify-start'
                            : index > 0
                            ? 'justify-end'
                            : ' justify-start'
                        }
                         `}
                    >
                      <video
                        playsInline
                        autoPlay
                        muted
                        loop
                        width="69.25%"
                        height="70%"
                        className="object-cover hidden sm:block"
                      >
                        <source src={image} type="video/mp4" />
                      </video>
                    </div>
                  ) : (
                    <img
                      src={image}
                      alt={desc}
                      className="sm:w-[69.5%] sm:h-[80%]"
                    />
                  )}
                </>
              }
            />
          );
        })}
      </HTMLFlipBook>
    </div>
  );
};

export default DesktopPreview;
