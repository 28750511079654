import React, { useRef, useState, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';

import Page from './Page';
import PrevButtonClick from './PrevButtonClick';
import NextButtonClick from './NextButtonClick';
import LoadingDesktop from '../images/LOADING_SCREEN_DESKTOP.gif';
import LoadingMobile from '../images/LOADING_SCREEN_MOBILE.gif';
import {
  COMIC_DISTRIBUTION,
  TOKEN_DISTRIBUTION,
  COMIC_ADDRESS,
  COVER_MEDIA_FILES,
} from 'constants';

import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { erc721Abi, verifyMessage, createPublicClient, http } from 'viem';
import { mainnet } from 'viem/chains';

const DesktopComic = () => {
  const book = useRef();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { comicId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // const { address: connectedAddress, isConnected } = useAccount();
  // const { connectAsync } = useConnect();
  // const { signMessage, signMessageAsync } = useSignMessage();

  const isBookClose = page === 0;

  const isBookFinished =
    pages.length % 2 === 0
      ? page === pages.length - 1
      : page === pages.length - 2;

  async function getComicPages(tokenId, comicDistribution, tokenDistribution) {
    // Step 1: Find the variant from the comicDistribution array using the tokenId

    let variantEntry;
    let variant;

    const getTokenMetadata = async () => {
      console.log('Fetching token metadata');

      const response = await fetch(`https://dev-meta.eonrift.com/api/comic-01/${tokenId}`);

      const data = await response.json();
      console.log('Data:', data);
      if (data.animation_url.endsWith('AP_EDITION+LEGENDARY_MOTION.mp4')) {
        return "Artist's Proof Edition V22"
      } else if (data.animation_url.endsWith('1ST_EDITION+LEGENDARY_MOTION.mp4')) {
        return "First Edition V22"
      } else if (data.animation_url.endsWith('1ST_EDITION_MOTION.mp4')) {
        return "First Edition V21"
      } else if (data.animation_url.endsWith('AP_EDITION_MOTION.mp4')) {
        return "Artist's Proof Edition V21"
      } else {
        return null
      }
    };

    const tokenMetadata = await getTokenMetadata();

    if (tokenMetadata) {
      variant = tokenMetadata;
    } else {


      variantEntry = comicDistribution.find(
        entry => entry['TOKEN ID'] === parseInt(tokenId)
      );

      if (!variantEntry) {
        throw new Error(`Variant not found for TOKEN ID ${tokenId}`);
      }

      // Extracts 'READER VARIENT' property ex: "Artist's Proof Edition V15"
      variant = variantEntry['READER VARIANT'];
    }
    // Step 2: Find the corresponding page numbers and file names for the variant from tokenDistribution
    const pages = [];
    const coverPages = [];

    for (let i = 0; i < tokenDistribution.length; i++) {
      const fileName = tokenDistribution[i]['File Name'];
      const pageNumber = tokenDistribution[i][variant];

      if (pageNumber !== '-' && pageNumber !== undefined) {
        if (COVER_MEDIA_FILES.includes(fileName)) {
          coverPages.push({
            id: parseInt(pageNumber, 10),
            image: 'https://ik.imagekit.io/7jme0kz43/eonrift/' + fileName,
          });

          if (coverPages.length > 1) {
            pages.push({
              id: parseInt(pageNumber, 10),
              image: 'null',
            });
            pages.push({
              id: parseInt(pageNumber, 10),
              image: 'https://ik.imagekit.io/7jme0kz43/eonrift/' + fileName,
            });
          } else {
            pages.push({
              id: parseInt(pageNumber, 10),
              image: 'https://ik.imagekit.io/7jme0kz43/eonrift/' + fileName,
            });
          }

          // console.log('Cover hit', parseInt(pageNumber, 10));
        } else {
          pages.push({
            id: parseInt(pageNumber, 10),
            image: 'https://ik.imagekit.io/7jme0kz43/eonrift/' + fileName,
          });
        }
      }
    }

    // console.log('Cover Pages:', coverPages);

    // Sort pages by id
    pages.sort((a, b) => a.id - b.id);
    setPages(pages);

    // TODO - remove this line for production
    // setPages(testArr);

    // console.log('Pages:', pages);

    return pages;
  }

  useEffect(() => {
    // TEST VALUES
    // comic id: 101
    // address: 0x7667A06a7b0c7E3185da4FA4e452A9d1cd24D9dB
    // sig:  0x23ed0d7850cc08539245fff6a8c2ca7f081f61d30233acbe86604fc33a644821694a2db4a4538d9fab447c71fb1a2ee2aa39c029a175787657624577382a918d1b
    const fetchData = async () => {
      const urlParams = new URLSearchParams(location.search);

      const address = urlParams.get('address');
      const sig = urlParams.get('signature');

      if (!comicId || !address || !sig) {
        throw new Error('Missing param in URL');
      }

      const messageToSign = `\nI am the owner of the comic with ID ${comicId}`;

      const result = await verifyMessage({
        address,
        message: messageToSign,
        signature: sig,
      });

      const publicClient = createPublicClient({
        chain: mainnet,
        transport: http(
          'https://eth-mainnet.g.alchemy.com/v2/C31RQ4vNcdQ98LGzf5Pf1FHjeNSVa0zh'
        ),
      });

      const comicOwner = await publicClient.readContract({
        address: COMIC_ADDRESS,
        abi: erc721Abi,
        functionName: 'ownerOf',
        args: [comicId],
      });

      // TODO - console logs everwhere
      console.log('Result:', result);

      const isOwner =
        comicOwner.toLocaleLowerCase() === address.toLocaleLowerCase();

      if (isOwner) {
      getComicPages(comicId, COMIC_DISTRIBUTION, TOKEN_DISTRIBUTION);
      setLoaded(true);
      } else {
        // getComicPages(comicId, COMIC_DISTRIBUTION, TOKEN_DISTRIBUTION);
        // setLoaded(true);
        navigate('/comic-01/preview');
        console.log('You are not the owner of this comic');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextButtonClick = () => {
    book.current.pageFlip().flipNext();
  };

  const prevButtonClick = () => {
    book.current.pageFlip().flipPrev();
  };

  const onPage = e => {
    setPage(e.data);
  };

  // FOR TESTING
  // const handleSign = async () => {
  //   const messageToSign = '\nI am the owner of the comic with ID 101';

  //   const signature = await signMessageAsync({ message: messageToSign });

  //   console.log('Signature:', signature);
  // };

  if (!loaded) {
    return (
      <div className="fixed inset-0 bg-white/30 backdrop-blur-sm flex flex-col items-center justify-center z-50">
        <img
          src={LoadingDesktop}
          alt="Loading"
          className="h-screen w-full hidden sm:block"
        />
        <img
          src={LoadingMobile}
          alt="Loading"
          className="h-screen w-full sm:hidden "
        />
      </div>
    );
  }

  return (
    <div
      className={`hidden justify-center items-center h-screen relative min-[630px]:flex`}
    >
      <div className="hidden xs:block absolute top-1/2 -translate-x-1/2 left-8 min-[1180px]:left-14 min-[2500px]:left-40">
        {!isBookClose && <PrevButtonClick buttonClick={prevButtonClick} />}
      </div>

      <div className="hidden xs:block  absolute top-1/2 -translate-x-1/2 right-[-25px] min-[1180px]:right-0  min-[2500px]:right-32">
        {!isBookFinished && <NextButtonClick buttonClick={nextButtonClick} />}
      </div>

      <HTMLFlipBook
        width={550}
        height={windowSize.width >= 640 ? 733 : 850}
        size="stretch"
        minWidth={315}
        maxWidth={875}
        minHeight={400}
        maxHeight={windowSize.height}
        showCover={true}
        mobileScrollSupport={true}
        onFlip={onPage}
        onChangeOrientation={book.onChangeOrientation}
        onChangeState={book.onChangeState}
        maxShadowOpacity={0}
        className="flex justify-center items-center "
        ref={book}
      >
        {pages.map(({ id, image, desc }, index) => {
          return (
            <Page
              key={id}
              number={id}
              desc={desc}
              currentPage={index}
              width={windowSize.width}
              isCover={
                image === 'null' ||
                COVER_MEDIA_FILES.includes(image.split('/').pop())
              }
              img={
                <>
                  {image.includes('.mp4') ? (
                    <div
                      className={` flex
                        ${index > 0 && index % 2 === 0
                          ? ' justify-start'
                          : index > 0
                            ? 'justify-end'
                            : ' justify-start'
                        }
                         `}
                    >
                      <video
                        playsInline
                        autoPlay
                        muted
                        loop
                        width="69.25%"
                        height="70%"
                        className="object-cover hidden sm:block"
                      >
                        <source
                          src={
                            image
                              ? image
                              : 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p16.mp4'
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  ) : image === 'null' ? (
                    <div className="hidden min-[630px]:block"></div>
                  ) : (
                    <img
                      src={
                        image
                          ? image
                          : 'https://ik.imagekit.io/7jme0kz43/eonrift/eonRift_i1-p01.png'
                      }
                      alt={desc}
                      className="sm:w-[69.5%] sm:h-[80%]"
                    />
                  )}
                </>
              }
            />
          );
        })}
      </HTMLFlipBook>
    </div>
  );
};

export default DesktopComic;
